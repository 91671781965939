// import {
//   log
// } from "util";

const endsWith = (subject, searchString, position) => {
  const subjectString = subject.toString();
  if (
    typeof position !== 'number' ||
    !isFinite(position) ||
    Math.floor(position) !== position ||
    position > subjectString.length
  ) {
    position = subjectString.length;
  }
  position -= searchString.length;
  const lastIndex = subjectString.indexOf(searchString, position);
  return lastIndex !== -1 && lastIndex === position;
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  fetching: false,
  success: null,
  error: null
};

export default function ajaxReducer(state = initialState, action) {
  if (endsWith(action.type, '_FETCH') || endsWith(action.type, '_SEND')) {
    return {
      ...state,
      fetching: true,
      success: null,
      error: null
    };
  }
  if (endsWith(action.type, '_SUCCESS')) {
    return {
      ...state,
      fetching: false,
      success: action.successAlert
    };
  }
  if (endsWith(action.type, '_ERROR')) {
    // console.log(action.payload);

    return {
      ...state,
      fetching: false,
      error: action.payload
    };
  }
  return state;
}
