import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

class Notifications extends React.Component {
  render() {
    if (!this.props.user) {
      return <div>Loading...</div>;
    }
    const {
      user: {
        notifications: { company }
      }
    } = this.props;
    return (
      <div className="Notifications_container">
        <div className="Notifications_nav_container">
          <div className="Notifications_nav_title">Your Notifications</div>
          {/* Commented out for now.
          <div className="Notifications_nav_clear">
            Clear Notifications
          </div> */}
        </div>
        <div className="Notifications_list_container">
          {company.length > 0 &&
            company.map(notification => {
              return (
                <div className="Notifications_list_item" key={`${notification._id}_component`}>
                  <img
                    className="Notifications_list_item_icon"
                    src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/logo_icon.png"
                    alt="Notification icon"
                  />
                  <div className="Notifications_list_item_message">{notification.title}</div>
                  <div className="Notifications_list_item_time">
                    {moment(notification.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                  </div>
                </div>
              );
            })}
          {company.length === 0 && <div>No notifications yet</div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
