import React from 'react';
import { withRouter } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

const NotFound = ({ history }) => (
  <div className="NotFound_background_container">
    <Image
      src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/404Page.png"
      fluid
      className="NotFound_message"
    />
    <button type="button" className="NotFound_main_back_button" onClick={() => history.goBack()}>
      Go Back
    </button>
  </div>
);

export default withRouter(NotFound);
