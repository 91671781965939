import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import InputNumber from 'rc-input-number';
import { Container, Grid, Dropdown } from 'semantic-ui-react';
import { editCreateTeam } from '../../../../reducers/sub_reducers/teamCreate';
import 'rc-input-number/assets/index.css';

const options = [
  { key: 1, text: '10X', value: 10 },
  { key: 2, text: '5X', value: 5 },
  { key: 3, text: '4X', value: 4 },
  { key: 4, text: '3X', value: 3 },
  { key: 5, text: '2X', value: 2 },
  { key: 6, text: '1X', value: 1 }
];
class Compensation extends Component {
  state = {
    cost: 0,
    roi: 0,
    rate: 0,
    price: 0,
    usingPrice: false
  };

  componentDidMount() {
    const { createdTeam } = this.props;
    if (createdTeam.profile.questions.leadPrice) {
      this.setState({
        price: createdTeam.profile.questions.leadPrice / 100,
        usingPrice: true
      });
    }
  }

  updateTeam = () => {
    const { price, cost, roi, rate, usingPrice } = this.state;
    const teamId = this.props.createdTeam._id;
    if (price > 0 && usingPrice) {
      this.props.editCreateTeam(1, teamId, {
        leadPrice: price * 100
      });
    } else if (cost > 0 && roi > 0 && rate > 0) {
      this.props.editCreateTeam(1, teamId, {
        leadPrice: Math.round((cost / roi) * (rate / 100)) * 100
      });
    } else {
      console.log('fail');
    }
  };

  togglePriceOff = () => {
    const { cost, roi, rate } = this.state;
    if (cost > 0 && roi > 0 && rate > 0) {
      this.setState({ usingPrice: false });
    }
  };

  onChangeRoi = (event, { value }) => {
    this.setState(
      {
        roi: value
      },
      () => {
        this.checkNewPrice();
      }
    );
  };

  onChangeCost = cost => {
    if (cost < 0) {
      return;
    }
    this.setState({ cost: Number(cost) }, () => {
      this.checkNewPrice();
    });
  };

  onChangeRate = rate => {
    if (rate < 0 || rate > 100) {
      return;
    }
    this.setState({ rate: Number(rate) }, () => {
      this.checkNewPrice();
    });
  };

  checkNewPrice = () => {
    const { cost, roi, rate } = this.state;
    if (cost > 0 && roi > 0 && rate > 0) {
      this.setState({ price: Math.round((cost / roi) * (rate / 100)), usingPrice: false });
    }
  };

  enterOwnPrice = price => {
    this.setState({ price, usingPrice: true });
  };

  render() {
    const { cost, roi, rate, price, usingPrice } = this.state;
    const { createdTeam } = this.props;
    if (!createdTeam) return <div>Loading...</div>;
    let calculatedPrice;
    if (cost > 0 && roi > 0 && rate > 0) {
      calculatedPrice = Math.round((cost / roi) * (rate / 100));
    } else {
      calculatedPrice = 0;
    }
    return (
      <React.Fragment>
        <Container className="TeamCreate_Container">
          <Grid className="Compensation_Container_Body">
            <Grid.Row className="TeamCreate_Container_Header">
              <div className="TeamCreate_Header">
                <div>
                  Answer the following questions to receive the recommended Lead Price to pay
                  ZoomReps.
                </div>
              </div>
            </Grid.Row>
            <div
              className={`Compensation_progress_bar ${cost > 0 &&
                roi > 0 &&
                rate > 0 &&
                'finished'}`}
            >
              <div className={`Compensation_progress_bubble_1 ${cost > 0 && 'finished'}`} />
              <div className={`Compensation_progress_bubble_2 ${roi > 0 && 'finished'}`} />
              <div className={`Compensation_progress_bubble_3 ${rate > 0 && 'finished'}`} />
            </div>
            <div className="Compensation_Main_Content">
              <div className="Compensation_Main_Content_questions">
                <div className="Compensation_Main_Content_questions_instance">
                  <div className="Compensation_Main_Content_questions_instance_text">
                    What is your product&apos;s sales price?
                  </div>
                  <InputNumber
                    className="Compensation_Main_Content_questions_instance_input"
                    formatter={value =>
                      value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                    }
                    name="cost"
                    value={cost > 0 ? cost : ''}
                    placeholder="e.g. $10,000"
                    min={0}
                    onChange={this.onChangeCost}
                  />
                </div>
                <div className="Compensation_Main_Content_questions_instance">
                  <div className="Compensation_Main_Content_questions_instance_text">
                    What is your required return on investment?
                  </div>
                  <Dropdown
                    className="Compensation_Main_Content_questions_instance_input"
                    clearable
                    name="roi"
                    options={options}
                    placeholder="e.g. 5x"
                    onChange={this.onChangeRoi}
                    selection
                  />
                </div>
                <div className="Compensation_Main_Content_questions_instance">
                  <div className="Compensation_Main_Content_questions_instance_text">
                    What is your product&apos;s closing rate?
                  </div>
                  <InputNumber
                    className="Compensation_Main_Content_questions_instance_input"
                    min={0}
                    max={100}
                    value={rate > 0 ? rate : ''}
                    formatter={() => (rate ? `${rate}%` : '')}
                    parser={value => value.replace('%', '')}
                    placeholder="e.g. 25%"
                    onChange={this.onChangeRate}
                  />
                </div>
              </div>
              <div className="Compensation_Main_Content_prices">
                <div
                  className={`Compensation_Main_Content_prices_recommended ${!usingPrice &&
                    calculatedPrice > 0 &&
                    'selected'}`}
                  onClick={this.togglePriceOff}
                  onKeyDown={this.togglePriceOff}
                >
                  <div className="Compensation_Main_Content_ribbon" />
                  <div className="Compensation_Main_Content_prices_recommended_title">
                    Recommended Price
                  </div>
                  <InputNumber
                    className={`Compensation_Main_Content_prices_recommended_input ${!usingPrice &&
                      calculatedPrice > 0 &&
                      'selected'}`}
                    readOnly
                    value={calculatedPrice || 0}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                  {!usingPrice && calculatedPrice > 0 && (
                    <div className="Hidden_stuff">
                      <div className="Compensation_Main_Content_prices_recommended_profit">
                        {`(Average profit of $${(calculatedPrice * roi - calculatedPrice)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per lead!)`}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`Compensation_Main_Content_prices_chosen ${usingPrice && 'selected'}`}
                >
                  <div className="Compensation_Main_Content_prices_recommended_title">
                    Enter your own Price
                  </div>
                  <InputNumber
                    className={`Compensation_Main_Content_prices_recommended_input ${usingPrice &&
                      'selected'}`}
                    name="price"
                    value={price}
                    onChange={this.enterOwnPrice}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                  {usingPrice && cost > 0 && rate > 0 && price > 0 && (
                    <div className="Hidden_stuff2">
                      <div className="Compensation_Main_Content_prices_recommended_profit">
                        {`(Average profit of $${Math.round(
                          (cost - price / rate / 100) / (100 / rate) - price
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per lead!)`}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Grid.Row className="Customization_Container_Bottom_Nav">
              <Link to={`/dashboard/create/${createdTeam._id}/recruitment`}>
                <button type="button" className="Recruitment_Bottom_Nav_Button">
                  Back
                </button>
              </Link>
              <button
                type="button"
                className={`Customization_Button_Next ${(price > 0 || calculatedPrice > 0) &&
                  'ready'}`}
                onClick={this.updateTeam}
              >
                Next
              </button>
            </Grid.Row>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, teamCreate: { createdTeam } }) => ({
  authorization,
  createdTeam
});

const mapDispatchToProps = {
  editCreateTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(Compensation);
