import axios from 'axios';
import { API_URL } from '../../common/utils/url';
import notify from '../../common/utils/notify';
import { history } from '../../store/configureStore';

const exampleInitialState = {
  settingsUser: null,
  companyStripe: null
};

export const actionTypes = {
  GET_SETTINGS_USER: 'GET_SETTINGS_USER',
  GET_COMPANY_STRIPE: 'GET_COMPANY_STRIPE',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_PHONE: 'CHANGE_PHONE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_COMPANY_NAME: 'CHANGE_COMPANY_NAME',
  CHANGE_COMPANY_DESCRIPTION: 'CHANGE_COMPANY_DESCRIPTION',
  CHANGE_COMPANY_LOGO: 'CHANGE_COMPANY_LOGO',
  CHANGE_COMPANY_STRIPE: 'CHANGE_COMPANY_STRIPE',
  INTEGRATE_SALESFORCE: 'INTEGRATE_SALESFORCE'
};

export const getSettingsUser = (userId, authorization) => async dispatch => {
  try {
    const foundUser = await axios.get(`${API_URL}/v1/user/${userId}`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.GET_SETTINGS_USER,
      settingsUser: foundUser.data
    });
  } catch (error) {
    return console.error(error);
  }
};

export const changeEmail = (userId, authorization, email) => async dispatch => {
  try {
    const foundUser = await axios.put(
      `${API_URL}/v1/user/${userId}/settings/email`,
      { email },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Please check your email!');
    history.push('/auth/verification');
    return dispatch({
      type: actionTypes.CHANGE_EMAIL,
      settingsUser: foundUser.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};
export const changePhone = (userId, authorization, phone) => async dispatch => {
  try {
    const foundUser = await axios.put(
      `${API_URL}/v1/user/${userId}/settings/phone`,
      { phone },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Phone changed!');
    return dispatch({
      type: actionTypes.CHANGE_PHONE,
      settingsUser: foundUser.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};

export const changePassword = (
  userId,
  authorization,
  oldPassword,
  newPassword
) => async dispatch => {
  try {
    const foundUser = await axios.put(
      `${API_URL}/v1/user/${userId}/settings/password`,
      { oldPassword, newPassword },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Password changed!');
    return dispatch({
      type: actionTypes.CHANGE_PASSWORD,
      settingsUser: foundUser.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};
export const changeCompanyName = (companyId, authorization, name) => async dispatch => {
  try {
    const foundUser = await axios.put(
      `${API_URL}/v1/company/${companyId}/settings`,
      { name },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Company name changed!');
    return dispatch({
      type: actionTypes.CHANGE_COMPANY_NAME,
      settingsUser: foundUser.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};
export const changeCompanyDescription = (
  companyId,
  authorization,
  description
) => async dispatch => {
  try {
    const foundUser = await axios.put(
      `${API_URL}/v1/company/${companyId}/settings`,
      { description },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Company description changed!');
    return dispatch({
      type: actionTypes.CHANGE_COMPANY_DESCRIPTION,
      settingsUser: foundUser.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};
export const changeCompanyLogo = (companyId, authorization, logo) => async dispatch => {
  try {
    const foundUser = await axios.put(
      `${API_URL}/v1/company/${companyId}/settings`,
      { logo },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Company logo changed!');
    return dispatch({
      type: actionTypes.CHANGE_COMPANY_LOGO,
      settingsUser: foundUser.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};
export const changeCompanyStripe = (authorization, token) => async dispatch => {
  try {
    const foundUser = await axios.post(
      `${API_URL}/v1/stripe/payment/save`,
      { token },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Company payment method added!');
    const foundStripe = await axios.get(`${API_URL}/v1/stripe/payment/info`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.CHANGE_COMPANY_STRIPE,
      settingsUser: foundUser.data,
      companyStripe: foundStripe.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};
export const getCompanyStripe = authorization => async dispatch => {
  try {
    const foundStripe = await axios.get(`${API_URL}/v1/stripe/payment/info`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.GET_COMPANY_STRIPE,
      companyStripe: foundStripe.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};

export const integrateSalesforce = (authorization, accessCode, userId) => async dispatch => {
  try {
    await axios.post(
      `${API_URL}/v1/integration/salesforce/getToken`,
      { accessCode },
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', 'Salesforce has been integrated!!!');
    const foundUser = await axios.get(`${API_URL}/v1/user/${userId}`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.INTEGRATE_SALESFORCE,
      settingsUser: foundUser.data
    });
  } catch (error) {
    notify('error', error.response.data.message);
    return console.error(error);
  }
};

export const settingsReducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTINGS_USER:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    case actionTypes.GET_COMPANY_STRIPE:
      return Object.assign({}, state, {
        companyStripe: action.companyStripe
      });
    case actionTypes.CHANGE_EMAIL:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    case actionTypes.CHANGE_PHONE:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    case actionTypes.CHANGE_PASSWORD:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    case actionTypes.CHANGE_COMPANY_NAME:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    case actionTypes.CHANGE_COMPANY_DESCRIPTION:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    case actionTypes.CHANGE_COMPANY_LOGO:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    case actionTypes.CHANGE_COMPANY_STRIPE:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser,
        companyStripe: action.companyStripe
      });
    case actionTypes.INTEGRATE_SALESFORCE:
      return Object.assign({}, state, {
        settingsUser: action.settingsUser
      });
    default:
      return state;
  }
};
