import React from 'react';
import { connect } from 'react-redux';
// import NumberFormat from 'react-number-format';
import { changeEmail, changePhone } from '../../../../reducers/sub_reducers/settings';
import notify from '../../../../common/utils/notify';

// const formatPhoneNumber = phoneNumberString => {
//   const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
//   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     return `(${match[1]}) ${match[2]}-${match[3]}`;
//   }
//   return null;
// };

class Comms extends React.Component {
  state = {
    email: '',
    emailEdit: false
    // phone: '',
    // phoneEdit: false
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleEmailEdit = () => {
    const { settingsUser, authorization, changeEmail } = this.props;
    this.setState(
      prevState => {
        return { emailEdit: !prevState.emailEdit };
      },
      () => {
        if (!this.state.emailEdit) {
          if (settingsUser.auth.email === this.state.email) {
            return notify('error', 'Cannot use same email!');
          }
          changeEmail(settingsUser._id, authorization, this.state.email);
        }
        return this.setState({
          email: settingsUser.auth.email
          // phone: settingsUser.auth.phone.number
        });
      }
    );
  };

  // togglePhoneEdit = () => {
  //   const { settingsUser, authorization, changePhone } = this.props;
  //   this.setState(
  //     prevState => {
  //       return { phoneEdit: !prevState.phoneEdit };
  //     },
  //     () => {
  //       if (!this.state.phoneEdit) {
  //         if (settingsUser.auth.phone.number === this.state.phone) {
  //           return notify('error', 'Cannot use same phone!');
  //         }
  //         changePhone(settingsUser._id, authorization, this.state.phone);
  //       }
  //       return this.setState({
  //         email: settingsUser.auth.email,
  //         phone: settingsUser.auth.phone.number
  //       });
  //     }
  //   );
  // };

  render() {
    const { settingsUser } = this.props;
    if (!settingsUser) return <div>Loading...</div>;
    const { email, emailEdit } = this.state;
    return (
      <div className="Settings_comms_container">
        <div className="Settings_comms_header">Contact Details</div>
        <div className="Settings_comms_fields_container">
          <div className="Settings_comms_field_email_container">
            <div className="Settings_comms_field_email_title">Email Address:</div>
            {emailEdit ? (
              <React.Fragment>
                <div className="Settings_comms_field_email_input">
                  <input
                    className="Settings_security_field_item_input"
                    onChange={this.onChange}
                    name="email"
                    value={email}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        this.toggleEmailEdit();
                      }
                    }}
                  />
                </div>
                <div
                  className="Settings_comms_field_email_edit"
                  onClick={this.toggleEmailEdit}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      this.toggleEmailEdit();
                    }
                  }}
                >
                  Save
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="Settings_comms_field_email_input">{settingsUser.auth.email}</div>
                <div
                  className="Settings_comms_field_email_edit"
                  onClick={this.toggleEmailEdit}
                  onKeyDown={this.toggleEmailEdit}
                >
                  Edit
                </div>
              </React.Fragment>
            )}
          </div>
          {/* <div className="Settings_comms_field_phone_container">
            <div className="Settings_comms_field_phone_title">Phone #:</div>
            {phoneEdit ? (
              <React.Fragment>
                <div className="Settings_comms_field_phone_input">
                  <NumberFormat
                    onChange={this.onChange}
                    name="phone"
                    value={phone}
                    format="(###) ###-####"
                    mask="_"
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        this.togglePhoneEdit();
                      }
                    }}
                  />
                </div>
                <div
                  className="Settings_comms_field_phone_edit"
                  onClick={this.togglePhoneEdit}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      this.togglePhoneEdit();
                    }
                  }}
                >
                  Save
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="Settings_comms_field_phone_input">
                  {formatPhoneNumber(settingsUser.auth.phone.number)}
                </div>
                <div
                  className="Settings_comms_field_phone_edit"
                  onClick={this.togglePhoneEdit}
                  onKeyDown={this.togglePhoneEdit}
                >
                  Edit
                </div>
              </React.Fragment>
            )}
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, settings: { settingsUser } }) => ({
  settingsUser,
  authorization
});

const mapDispatchToProps = {
  changeEmail,
  changePhone
};

export default connect(mapStateToProps, mapDispatchToProps)(Comms);
