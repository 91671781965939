import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Rating } from 'semantic-ui-react';
import moment from 'moment';

class RepReviews extends Component {
  state = {
    showModal: false
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { rep } = this.props;
    if (!rep) return <div>Loading...</div>;
    const ratingTrigger = (
      <div
        className="RepReviews_modal_trigger"
        onClick={() => this.setState({ showModal: true })}
        onKeyPress={this.closeModal}
      >
        {`${rep.profile.reviews.length} reviews`}
      </div>
    );
    return (
      <Modal
        trigger={ratingTrigger}
        closeIcon
        onClose={this.closeModal}
        open={this.state.showModal}
        size="small"
      >
        <Modal.Content>
          <div className="RepReviews_container">
            <div className="RepReviews_container_header">
              <img
                src={rep.profile.profilePicture}
                className="RepReviews_container_header_picture"
                alt="rep profile"
              />
              <div className="RepReviews_container_header_description">
                <div className="RepReviews_container_header_description_name">
                  {rep.profile.firstName} {rep.profile.lastName}
                </div>
                <div className="RepReviews_container_header_description_since">
                  Rep Since{' '}
                  {moment(rep.createdAt)
                    .utc()
                    .format('MMMM Do YYYY')}
                </div>
                <div className="RepReviews_container_header_rating">
                  <div className="RepReviews_container_header_rating_number">
                    {rep.profile.reviews
                      .reduce((acc, review) => {
                        return acc + review.rating;
                      }, 0)
                      .toFixed(1)}
                  </div>
                  <Rating
                    className="RepReviews_container_header_rating_rating"
                    maxRating={5}
                    defaultRating={rep.profile.reviews.reduce((acc, review) => {
                      return acc + review.rating;
                    }, 0)}
                    icon="star"
                    size="large"
                    disabled
                  />
                  <div className="RepReviews_container_header_rating_review">
                    {`${rep.profile.reviews.length} reviews`}
                  </div>
                </div>
              </div>
            </div>
            {rep.profile.reviews.length > 0 && (
              <div className="RepReviews_container_list_container">
                {rep.profile.reviews.map(review => {
                  return (
                    <div className="RepReviews_container_list_instance" key={review._id}>
                      <img
                        className="RepReviews_container_list_instance_top_company"
                        src={review.company.profile.logo}
                        alt="company logo"
                      />
                      <Rating
                        className="RepReviews_container_list_instance_top_rating"
                        maxRating={5}
                        defaultRating={review.rating}
                        icon="star"
                        disabled
                      />
                      <div className="RepReviews_container_list_instance_bottom">
                        {review.content}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {rep.profile.reviews.length === 0 && (
              <div className="Reviews_Placeholder_container">
                <img
                  src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/Revoews.png"
                  className="Reviews_Placeholder_image_container"
                  alt="Placeholder for empty team"
                />
                <div className="Reviews_Placeholder_text_container">
                  Sorry, there are no reviews yet for this Rep!
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default RepReviews;
