import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import { Modal, Rating } from 'semantic-ui-react';
import moment from 'moment';
import { getTeamJobs } from '../../../reducers/sub_reducers/teamDetail';

class CompanyReviews extends Component {
  state = {
    showModal: false
  };

  closeModal = event => {
    event.stopPropagation();
    this.setState({ showModal: false });
  };

  openModal = event => {
    event.stopPropagation();
    this.setState({ showModal: true });
  };

  render() {
    const { company } = this.props;
    if (!company) return <div>Loading...</div>;
    const ratingTrigger = (
      <div
        className="Team_banner_center_header_rating_reviews"
        onClick={this.openModal}
        onKeyPress={this.closeModal}
      >
        {`${company.profile.reviews.length} reviews`}
      </div>
    );
    return (
      <Modal
        trigger={ratingTrigger}
        closeIcon
        onClose={this.closeModal}
        open={this.state.showModal}
        size="small"
      >
        <Modal.Content>
          <div className="CompanyReviews_container">
            <div className="CompanyReviews_container_header">
              <img
                src={company.profile.logo}
                className="CompanyReviews_container_header_picture"
                alt="rep profile"
              />
              <div className="CompanyReviews_container_header_description">
                {/* <div className="CompanyReviews_container_header_description_name">
                  {rep.profile.firstName} {rep.profile.lastName}
                </div>
                <div className="CompanyReviews_container_header_description_since">
                  Rep Since{' '}
                  {moment(rep.createdAt)
                    .utc()
                    .format('MMMM Do YYYY')}
                </div> */}
                <div className="CompanyReviews_container_header_rating">
                  <div className="CompanyReviews_container_header_rating_number">
                    {company.profile.reviews
                      .reduce((acc, review) => {
                        return acc + review.rating;
                      }, 0)
                      .toFixed(1)}
                  </div>
                  <Rating
                    className="CompanyReviews_container_header_rating_rating"
                    maxRating={5}
                    defaultRating={company.profile.reviews.reduce((acc, review) => {
                      return acc + review.rating;
                    }, 0)}
                    icon="star"
                    size="large"
                    disabled
                  />
                  <div className="CompanyReviews_container_header_rating_review">
                    {`${company.profile.reviews.length} reviews`}
                  </div>
                </div>
              </div>
            </div>
            {company.profile.reviews.length > 0 && (
              <div className="CompanyReviews_container_list_container">
                {company.profile.reviews.map(review => {
                  return (
                    <div className="CompanyReviews_container_list_instance" key={review._id}>
                      <img
                        className="CompanyReviews_container_list_instance_top_rep_image"
                        src={review.user.profile.profilePicture}
                        alt="company logo"
                      />
                      <div className="CompanyReviews_container_list_instance_top_rep_name">
                        {review.user.profile.firstName} {review.user.profile.lastName}
                      </div>
                      <div className="CompanyReviews_container_list_instance_top_rep_since">
                        Rep Since{' '}
                        {moment(review.user.createdAt)
                          .utc()
                          .format('MMMM Do YYYY')}
                      </div>
                      <Rating
                        className="CompanyReviews_container_list_instance_top_rating"
                        maxRating={5}
                        defaultRating={review.rating}
                        icon="star"
                        disabled
                      />
                      <div className="CompanyReviews_container_list_instance_bottom">
                        {review.content}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {company.profile.reviews.length === 0 && (
              <div className="Reviews_Placeholder_container">
                <img
                  src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/Revoews.png"
                  className="Reviews_Placeholder_image_container"
                  alt="Placeholder for empty team"
                />
                <div className="Reviews_Placeholder_text_container">
                  Sorry, there are no reviews yet for this Company!
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = ({ auth: { user, authorization }, teamDetail: { detailTeam } }) => ({
  user,
  detailTeam,
  authorization
});

const mapDispatchToProps = {
  getTeamJobs
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyReviews);
