import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        },
        padding
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};
class CardForm extends React.Component {
  submit = async () => {
    const { user } = this.props;
    const { token } = await this.props.stripe.createToken({
      type: 'card',
      name: user.auth.company.profile.name
    });
    this.props.setNewBank(token);
  };

  render() {
    return (
      <form onSubmit={this.submit} className="Card_form">
        <label className="Card_label">
          Enter card details
          <div className="Card_seperator" />
          <CardElement {...createOptions(this.props.fontSize)} />
        </label>
        <button type="button" onClick={this.submit} className="Card_button">
          Save Card
        </button>
      </form>
    );
  }
}

export default injectStripe(CardForm);
