import React from 'react';
import { connect } from 'react-redux';
import {
  changeCompanyName,
  changeCompanyDescription,
  changeCompanyLogo
} from '../../../../reducers/sub_reducers/settings';
import { refetchUser } from '../../../../reducers/sub_reducers/auth';
import notify from '../../../../common/utils/notify';
import ImageCrop from '../../common/ImageCrop';

class Company extends React.Component {
  state = {
    name: '',
    nameEdit: false,
    description: '',
    descriptionEdit: false
  };

  fileInputRef = React.createRef();

  onChange = event => {
    if (event.target.name === 'name' && event.target.value.length > 20) {
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleNameEdit = () => {
    const {
      settingsUser: {
        auth: { company }
      },
      authorization,
      changeCompanyName,
      refetchUser
    } = this.props;

    this.setState(
      prevState => {
        return { nameEdit: !prevState.nameEdit };
      },
      () => {
        if (!this.state.nameEdit) {
          if (company.profile.name === this.state.name) {
            return notify('error', 'Cannot use same name!');
          }
          return changeCompanyName(company._id, authorization, this.state.name).then(() => {
            refetchUser();
          });
        }
        return this.setState({
          name: company.profile.name
        });
      }
    );
  };

  toggleDescriptionEdit = () => {
    const {
      settingsUser: {
        auth: { company }
      },
      authorization,
      changeCompanyDescription
    } = this.props;
    this.setState(
      prevState => {
        return { descriptionEdit: !prevState.descriptionEdit };
      },
      () => {
        if (!this.state.descriptionEdit) {
          if (company.profile.description === this.state.description) {
            return notify('error', 'Cannot use same description!');
          }
          return changeCompanyDescription(company._id, authorization, this.state.description);
        }
        return this.setState({
          description: company.profile.description
        });
      }
    );
  };

  render() {
    const { settingsUser } = this.props;
    if (!settingsUser) return <div>Loading...</div>;
    const { name, nameEdit, description, descriptionEdit } = this.state;
    return (
      <div className="Settings_company_container">
        <div className="Settings_company_header">Company Profile</div>
        <div className="Settings_company_flex_container">
          <div className="Settings_company_fields_container">
            <div className="Settings_company_field_name_container">
              <div className="Settings_company_field_name_title">Name (max 20 char):</div>
              {nameEdit ? (
                <React.Fragment>
                  <div className="Settings_company_field_name_input">
                    <input
                      className="Settings_security_field_item_input"
                      onChange={this.onChange}
                      name="name"
                      value={name}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          this.toggleNameEdit();
                        }
                      }}
                    />
                  </div>
                  <div
                    className="Settings_company_field_name_edit"
                    onClick={this.toggleNameEdit}
                    onKeyDown={this.toggleNameEdit}
                  >
                    Save
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="Settings_company_field_name_input">
                    {settingsUser.auth.company.profile.name}
                  </div>
                  <div
                    className="Settings_company_field_name_edit"
                    onClick={this.toggleNameEdit}
                    onKeyDown={this.toggleNameEdit}
                  >
                    Edit
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="Settings_company_field_description_container">
              <div className="Settings_company_field_name_title">Description:</div>
              {descriptionEdit ? (
                <React.Fragment>
                  <div className="Settings_company_field_description_input">
                    <input
                      className="Settings_security_field_item_input"
                      onChange={this.onChange}
                      name="description"
                      value={description}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          this.toggleDescriptionEdit();
                        }
                      }}
                    />
                  </div>
                  <div
                    className="Settings_company_field_description_edit"
                    onClick={this.toggleDescriptionEdit}
                    onKeyDown={this.toggleDescriptionEdit}
                    value={description}
                  >
                    Save
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="Settings_company_field_description_input">
                    {settingsUser.auth.company.profile.description}
                  </div>
                  <div
                    className="Settings_company_field_description_edit"
                    onClick={this.toggleDescriptionEdit}
                    onKeyDown={this.toggleDescriptionEdit}
                    value={description}
                  >
                    Edit
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="Settings_company_logo_container">
            <img
              src={settingsUser.auth.company.profile.logo}
              alt="logo"
              className="Settings_company_logo_image"
            />
            <ImageCrop />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user, authorization }, settings: { settingsUser } }) => ({
  user,
  settingsUser,
  authorization
});

const mapDispatchToProps = {
  changeCompanyName,
  changeCompanyDescription,
  changeCompanyLogo,
  refetchUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
