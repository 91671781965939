import axios from 'axios';
import { API_URL } from '../../common/utils/url';
import notify from '../../common/utils/notify';

const exampleInitialState = {
  teamsActive: null,
  teamsSaved: null,
  teamsCompany: null
};

export const actionTypes = {
  GET_TEAM_LIST: 'GET_TEAM_LIST'
};

export const getTeamList = (companyId, authorization) => async dispatch => {
  try {
    const teams = await axios.get(`${API_URL}/v1/company/${companyId}`, {
      headers: {
        authorization
      }
    });
    const sortedTeams = {
      saved: [],
      active: []
    };
    teams.data.teams.forEach(team => {
      sortedTeams[team.status].push(team);
    });
    return dispatch({
      type: actionTypes.GET_TEAM_LIST,
      teamsSaved: sortedTeams.saved,
      teamsActive: sortedTeams.active,
      teamsCompany: teams.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const teamListReducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEAM_LIST:
      return Object.assign({}, state, {
        teamsActive: action.teamsActive,
        teamsSaved: action.teamsSaved,
        teamsCompany: action.teamsCompany
      });
    default:
      return state;
  }
};
