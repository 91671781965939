import React from 'react';
import { Form, TextArea, Button, Modal, List } from 'semantic-ui-react';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../../../common/utils/url';
import notify from '../../../../common/utils/notify';

class BugReport extends React.Component {
  state = {
    report: '',
    showModal: false
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  submitReport = event => {
    event.preventDefault();
    const { authorization } = this.props;
    return axios
      .post(
        `${API_URL}/v1/ticket`,
        {
          type: 'report',
          title: 'Bug Report from frontend',
          description: this.state.report
        },
        {
          headers: {
            authorization
          }
        }
      )
      .then(() => {
        this.setState({ report: '', showModal: false });
        notify('success', 'Bug Report submitted!');
      })
      .catch(error => {
        notify('error', error.response.data.message);
        console.error(error);
      });
  };

  render() {
    const trigger = (
      <List.Item
        as="a"
        onClick={() => {
          this.setState({ showModal: true });
        }}
      >
        Bug Report
      </List.Item>
    );
    return (
      <Modal
        trigger={trigger}
        size="mini"
        centered
        className="document_modal"
        open={this.state.showModal}
        closeIcon
        onClose={this.closeModal}
      >
        <Modal.Header>Bug Report</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={this.submitReport}>
              <TextArea
                placeholder="Tell us more"
                value={this.state.report}
                name="report"
                onChange={this.onChange}
                style={{ minHeight: 100 }}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button color="red" onClick={this.closeModal} className="Feedback_button">
            Cancel
          </Button>
          <Button color="green" onClick={this.submitReport} className="Feedback_button">
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProps = ({ auth: { authorization } }) => ({
  authorization
});

export default connect(mapStateToProps)(BugReport);
