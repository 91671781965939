import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import { Button, Modal, Popup } from 'semantic-ui-react';
import axios from 'axios';
import { changeCompanyLogo } from '../../../reducers/sub_reducers/settings';
import { API_URL } from '../../../common/utils/url';
import notify from '../../../common/utils/notify';

class ImageCrop extends Component {
  state = {
    src: null,
    crop: {
      unit: '%',
      width: 50,
      aspect: 16 / 4
    },
    cropping: false
  };

  fileInputRef = React.createRef();

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ cropping: true });
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/png');
    });
  }

  uploadImage = async () => {
    const {
      settingsUser: {
        auth: { company }
      },
      authorization,
      changeCompanyLogo
    } = this.props;
    const blob = await fetch(this.state.croppedImageUrl).then(r => r.blob());
    const data = new FormData();
    data.append('logo', blob);
    axios
      .post(`${API_URL}/v1/service/upload/logo`, data, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      })
      .then(({ data }) => {
        const logoLocation = data.data.Location;
        this.setState({ cropping: false }, () => {
          changeCompanyLogo(company._id, authorization, logoLocation);
        });
      })
      .catch(error => {
        notify('error', error.response.data.message);
        console.error(error);
      });
  };

  stopCropping = () => {
    this.setState({ cropping: false });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.png');
      this.setState({ croppedImageUrl });
    }
  }

  render() {
    const { crop, src } = this.state;
    const imageTrigger = (
      <Button
        content="Change Logo"
        labelPosition="left"
        icon="file"
        onClick={() => this.fileInputRef.current.click()}
        className="Settings_company_logo_button"
      />
    );
    return (
      <div className="ImageCrop">
        {!this.state.cropping && (
          <div>
            <Popup
              position="top center"
              trigger={imageTrigger}
              className="Settings_company_logo_ImageCrop"
            >
              <div className="Customization_Image_Crop_info_popup">Aspect Ratio: 4:1</div>
            </Popup>
            <input
              ref={this.fileInputRef}
              type="file"
              onChange={this.onSelectFile}
              className="Settings_company_logo_input"
            />
          </div>
        )}
        {this.state.cropping && src && (
          <Modal open={this.state.cropping} onClose={this.stopCropping} size="mini" closeIcon>
            <Modal.Header>Crop Your Logo</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
                <Button
                  content="Change Logo"
                  labelPosition="left"
                  icon="file"
                  onClick={this.uploadImage}
                  className="Settings_company_logo_button_upload"
                />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {/* {croppedImageUrl && <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />} */}
      </div>
    );
  }
}
const mapStateToProps = ({ auth: { user, authorization }, settings: { settingsUser } }) => ({
  user,
  settingsUser,
  authorization
});

const mapDispatchToProps = {
  changeCompanyLogo
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCrop);
