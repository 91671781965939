import React from 'react';

import DesktopContainer from './DesktopContainer';

const Layout = ({ location, children }) => {
  return (
    <React.Fragment>
      <DesktopContainer location={location}>{children}</DesktopContainer>
      {/* <MobileContainer>{children}</MobileContainer> */}
    </React.Fragment>
  );
};

export default Layout;
