import React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect, Switch, NavLink } from 'react-router-dom';

import { Button, Icon } from 'semantic-ui-react';

import ChooseRep from './sub_manage/ChooseRep';
import TeamView from './sub_manage/TeamView';
import DetailTeamBanner from '../../common/banners/DetailTeamBanner';

class Manage extends React.Component {
  render() {
    const {
      location: { pathname },
      detailTeam
    } = this.props;
    const activeItem = pathname.endsWith('chooserep') ? 'chooserep' : 'teamview';
    if (!detailTeam) return <div>Loading</div>;
    return (
      <div className="Detail_manage_container">
        <div className="Detail_side_container">
          <div className="Detail_side_nav">
            <Button.Group vertical className="Detail_side_nav_buttons">
              <Button
                className="Detail_side_nav_button_upper"
                as={NavLink}
                to={`${this.props.match.url}/chooserep`}
                style={{
                  color: activeItem === 'chooserep' ? 'white' : 'black',
                  backgroundColor: activeItem === 'chooserep' ? '#55bbec' : 'white'
                }}
              >
                Select ZoomReps
                {activeItem === 'chooserep' && (
                  <Icon name="chevron right" className="Detail_side_nav_buttons_icon" />
                )}
              </Button>
              <Button
                style={{
                  color: activeItem === 'teamview' ? 'white' : 'black',
                  backgroundColor: activeItem === 'teamview' ? '#55bbec' : 'white'
                }}
                className="Detail_side_nav_button_lower"
                as={NavLink}
                to={`${this.props.match.url}/teamview`}
              >
                Team View
                {activeItem === 'teamview' && (
                  <Icon name="chevron right" className="Detail_side_nav_buttons_icon" />
                )}
              </Button>
            </Button.Group>
          </div>
          <div className="Detail_side_banner_container">
            {detailTeam && <DetailTeamBanner team={detailTeam} location={location} />}
          </div>
        </div>
        <div className="Detail_main_container">
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}`}
              component={() => <Redirect to={`${this.props.match.url}/chooserep`} />}
            />
            <Route path={`${this.props.match.url}/chooserep`} component={ChooseRep} />
            <Route path={`${this.props.match.url}/teamview`} component={TeamView} />
            <Route
              path={`${this.props.match.url}/*`}
              render={() => {
                return <Redirect to="/NotFound" />;
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth: { user, authorization }, teamDetail: { detailTeam } }) => ({
  user,
  authorization,
  detailTeam
});

export default connect(mapStateToProps)(Manage);
