import React from 'react';
import { connect } from 'react-redux';
import { changePassword } from '../../../../reducers/sub_reducers/settings';
import notify from '../../../../common/utils/notify';

class Security extends React.Component {
  state = { oldPassword: '', newPassword1: '', newPassword2: '' };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changePassword = () => {
    const { settingsUser, authorization, changePassword } = this.props;
    const { oldPassword, newPassword1, newPassword2 } = this.state;
    this.setState(
      {
        oldPassword: '',
        newPassword1: '',
        newPassword2: ''
      },
      () => {
        if (newPassword1 !== newPassword2) {
          return notify('error', 'Passwords do not match!');
        }
        if (oldPassword === newPassword1) {
          return notify('error', 'Cannot change password to the same password!');
        }
        return changePassword(settingsUser._id, authorization, oldPassword, newPassword1);
      }
    );
  };

  render() {
    const { oldPassword, newPassword1, newPassword2 } = this.state;
    return (
      <div className="Settings_comms_container">
        <div className="Settings_comms_header">Change Password Form</div>
        <div className="Settings_security_field_container">
          <div className="Settings_security_field_item_container">
            <div className="Settings_security_field_item_title">Current Password</div>
            <input
              className="Settings_security_field_item_input"
              name="oldPassword"
              type="password"
              value={oldPassword}
              onChange={this.onChange}
              placeholder="Current Password"
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.changePassword();
                }
              }}
            />
          </div>
          <div className="Settings_security_field_item_container">
            <div className="Settings_security_field_item_title">New Password</div>
            <input
              className="Settings_security_field_item_input"
              name="newPassword1"
              type="password"
              value={newPassword1}
              onChange={this.onChange}
              placeholder="New Password"
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.changePassword();
                }
              }}
            />
          </div>
          <div className="Settings_security_field_item_container">
            <div className="Settings_security_field_item_title">Confirm Password</div>
            <input
              className="Settings_security_field_item_input"
              name="newPassword2"
              type="password"
              value={newPassword2}
              onChange={this.onChange}
              placeholder="Confirm Password"
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.changePassword();
                }
              }}
            />
          </div>
        </div>
        <div className="Settings_security_save_container">
          <button
            type="button"
            className="Settings_security_save_button"
            onClick={this.changePassword}
          >
            Change Password
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, settings: { settingsUser } }) => ({
  authorization,
  settingsUser
});

const mapDispatchToProps = {
  changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
