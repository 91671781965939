import React from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import History from './sub_payments/History';
import Transfers from './sub_payments/Transfers';
import DetailTeamBanner from '../../common/banners/DetailTeamBanner';

class Payments extends React.Component {
  render() {
    const {
      location: { pathname },
      detailTeam
    } = this.props;
    const activeItem = pathname.endsWith('history') ? 'history' : 'transfers';
    if (!detailTeam) return <div>Loading</div>;
    return (
      <div className="Detail_payments_container">
        <div className="Detail_side_container">
          <div className="Detail_side_nav">
            <Button.Group vertical className="Detail_side_nav_buttons">
              <Button
                className="Detail_side_nav_button_upper"
                as={NavLink}
                to={`${this.props.match.url}/history`}
                style={{
                  color: activeItem === 'history' ? 'white' : 'black',
                  backgroundColor: activeItem === 'history' ? '#55bbec' : 'white'
                }}
              >
                Payment History
                {activeItem === 'history' && (
                  <Icon name="chevron right" className="Detail_side_nav_buttons_icon" />
                )}
              </Button>
              <Button
                style={{
                  color: activeItem === 'transfers' ? 'white' : 'black',
                  backgroundColor: activeItem === 'transfers' ? '#55bbec' : 'white'
                }}
                className="Detail_side_nav_button_lower"
                as={NavLink}
                to={`${this.props.match.url}/transfers`}
              >
                Transfers
                {activeItem === 'transfers' && (
                  <Icon name="chevron right" className="Detail_side_nav_buttons_icon" />
                )}
              </Button>
            </Button.Group>
          </div>
          <div className="Detail_side_banner_container">
            {detailTeam && <DetailTeamBanner team={detailTeam} location={location} />}
          </div>
        </div>
        <div className="Detail_payments_main_container">
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}`}
              component={() => <Redirect to={`${this.props.match.url}/history`} />}
            />
            <Route path={`${this.props.match.url}/history`} component={History} />
            <Route path={`${this.props.match.url}/transfers`} component={Transfers} />
            <Route
              path={`${this.props.match.url}/*`}
              render={() => {
                return <Redirect to="/NotFound" />;
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user, authorization }, teamDetail: { detailTeam } }) => ({
  user,
  authorization,
  detailTeam
});

export default connect(mapStateToProps)(Payments);
