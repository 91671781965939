import React from 'react';
import { Container, Grid, Header, List, Segment, Modal, Button, Icon } from 'semantic-ui-react';
import BugReport from './BugReport';
import Feedback from './Feedback';

const Footer = () => {
  return (
    <div className="Desktop_container_footer">
      <Segment inverted vertical>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header className="Footer_header_instance" inverted as="h4" content="About" />
                <List link inverted>
                  <Modal
                    trigger={<List.Item as="a">Team</List.Item>}
                    size="mini"
                    centered
                    className="document_modal"
                    closeIcon
                  >
                    <Modal.Header>The Team</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>Powered by 10X VENTURES!</Modal.Description>
                    </Modal.Content>
                  </Modal>
                  <Modal
                    trigger={<List.Item as="a">Mission Statement</List.Item>}
                    size="mini"
                    centered
                    className="document_modal"
                    closeIcon
                  >
                    <Modal.Header>Mission Statement</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        MetricZoom is the first application that connects companies requiring b2b
                        sales appointments with a talented on demand workforce. Our Freelance
                        Marketplace helps companies hire the most qualified talent for scheduling
                        sales appointments, on a pay for performance basis. Using MetricZoom
                        companies can efficiently scale sales generation campaigns and track
                        successes through an integrated CRM interface.
                      </Modal.Description>
                    </Modal.Content>
                  </Modal>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header className="Footer_header_instance" inverted as="h4" content="Support" />
                <List link inverted>
                  <Feedback />
                  <BugReport />
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header className="Footer_header_instance" inverted as="h4" content="Documents" />
                <List link inverted>
                  <Modal
                    trigger={<List.Item as="a">Terms & Conditions</List.Item>}
                    size="mini"
                    centered
                    className="document_modal"
                    closeIcon
                  >
                    <Modal.Header>Terms & Conditions</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        <Button
                          size="small"
                          color="green"
                          href="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/documents/MetricZoom+Terms+of+Use.docx"
                          download
                          className="document_modal_button"
                        >
                          <Icon name="download" />
                          Download Terms & Conditions
                        </Button>
                      </Modal.Description>
                    </Modal.Content>
                  </Modal>
                  <Modal
                    trigger={<List.Item as="a">Privacy Policy</List.Item>}
                    size="mini"
                    centered
                    className="document_modal"
                    closeIcon
                  >
                    <Modal.Header>Privacy Policy</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        <Button
                          size="small"
                          color="green"
                          href="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/documents/MetricZoom+Privacy+Policy.docx"
                          download
                          className="document_modal_button"
                        >
                          <Icon name="download" />
                          Download Privacy Policy
                        </Button>
                      </Modal.Description>
                    </Modal.Content>
                  </Modal>
                  <Modal
                    trigger={<List.Item as="a">EULA</List.Item>}
                    size="mini"
                    centered
                    className="document_modal"
                    closeIcon
                  >
                    <Modal.Header>EULA</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        <Button
                          size="small"
                          color="green"
                          href="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/documents/MetricZoom+End-User+License+Agreement+(EULA).docx"
                          download
                          className="document_modal_button"
                        >
                          <Icon name="download" />
                          Download EULA
                        </Button>
                      </Modal.Description>
                    </Modal.Content>
                  </Modal>
                  <Modal
                    trigger={<List.Item as="a">Cookie Policy</List.Item>}
                    size="mini"
                    centered
                    className="document_modal"
                    closeIcon
                  >
                    <Modal.Header>Cookie Policy</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        <Button
                          size="small"
                          color="green"
                          href="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/documents/MetricZoom+Cookie+Policy.docx"
                          download
                          className="document_modal_button"
                        >
                          <Icon name="download" />
                          Download Cookie Policy
                        </Button>
                      </Modal.Description>
                    </Modal.Content>
                  </Modal>
                </List>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as="h4" className="Footer_header_instance" inverted>
                  MetricZoom
                </Header>
                <p>For any other questions, please contact support@metriczoom.com!</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </div>
  );
};
export default Footer;
