import React from 'react';
import { Route, Redirect, Switch, NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
// Local
import {
  getTeamDetail,
  getTeamJobs,
  getTeamApplications,
  getTeamBalance,
  getTeamTransactions
} from '../../../reducers/sub_reducers/teamDetail';
// Components
import Manage from './sub_team_detail/Manage';
import Integrations from './sub_team_detail/Integrations';
import Payments from './sub_team_detail/Payments';

class TeamDetail extends React.Component {
  componentDidMount() {
    const {
      authorization,
      getTeamDetail,
      getTeamJobs,
      getTeamApplications,
      getTeamBalance,
      getTeamTransactions,
      match
    } = this.props;
    getTeamDetail(authorization, match.params.teamId).then(() => {
      getTeamJobs(authorization, match.params.teamId);
      getTeamApplications(authorization, match.params.teamId);
      getTeamBalance(authorization, match.params.teamId);
      getTeamTransactions(authorization, match.params.teamId);
    });
  }

  render() {
    const {
      location: { pathname },
      detailTeam
    } = this.props;
    let activeItem;
    if (pathname.endsWith('chooserep') || pathname.endsWith('teamview')) {
      activeItem = 'manage';
    } else if (pathname.endsWith('apps') || pathname.endsWith('connected')) {
      activeItem = 'integrations';
    } else if (pathname.endsWith('history') || pathname.endsWith('transfers')) {
      activeItem = 'payments';
    }
    if (!detailTeam) return <div>Loading...</div>;
    return (
      <div className="Detail_container">
        <div className="Detail_nav">
          <div className="Detail_nav_left">
            <div className="Detail_nav_left_location">
              <Link to="/dashboard/teamlist/active">
                <Icon name="chevron left" />{' '}
                <span className="Detail_nav_left_link_text">Back to Teams </span>
              </Link>
            </div>
          </div>
          <div className="Detail_nav_center">
            <Button.Group className="Detail_nav_center_buttons">
              <Button
                as={NavLink}
                to={`${this.props.match.url}/manage`}
                name="manage"
                className="Detail_nav_center_button_left"
                style={{
                  color: activeItem === 'manage' ? 'white' : 'black',
                  backgroundColor: activeItem === 'manage' ? '#55bbec' : 'white'
                }}
              >
                Manage
              </Button>
              <Button
                as={NavLink}
                to={`${this.props.match.url}/integrations`}
                name="integrations"
                className="Detail_nav_center_button_left"
                style={{
                  color: activeItem === 'integrations' ? 'white' : 'black',
                  backgroundColor: activeItem === 'integrations' ? '#55bbec' : 'white'
                }}
              >
                Integrations
              </Button>
              <Button
                as={NavLink}
                to={`${this.props.match.url}/payments`}
                name="payments"
                className="Detail_nav_center_button_right"
                style={{
                  color: activeItem === 'payments' ? 'white' : 'black',
                  backgroundColor: activeItem === 'payments' ? '#55bbec' : 'white'
                }}
              >
                Payments
              </Button>
            </Button.Group>
          </div>
          <div className="Detail_nav_right" />
        </div>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}`}
            component={() => <Redirect to={`${this.props.match.url}/manage`} />}
          />
          <Route path={`${this.props.match.url}/manage`} component={Manage} />
          <Route path={`${this.props.match.url}/integrations`} component={Integrations} />
          <Route path={`${this.props.match.url}/payments`} component={Payments} />
          <Route
            path={`${this.props.match.url}/*`}
            render={() => {
              return <Redirect to="/NotFound" />;
            }}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user, authorization }, teamDetail: { detailTeam } }) => ({
  user,
  authorization,
  detailTeam
});

const mapDispatchToProps = {
  getTeamDetail,
  getTeamJobs,
  getTeamApplications,
  getTeamBalance,
  getTeamTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetail);
