import React from 'react';
import { Icon, Image, Menu, Dropdown, Popup } from 'semantic-ui-react';
import { NavLink, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import moment from 'moment';
import { signOut } from '../../../reducers/sub_reducers/auth';

import Footer from './sub_layout/Footer';

class DesktopContainer extends React.Component {
  state = { isOpen: false };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { children, signOut, user, location } = this.props;
    if (!user) {
      return <div>Loading...</div>;
    }
    if (!user || !location) return <div>Loading...</div>;
    const trigger = (
      <span className="Desktop_menu_dropdown_account">
        <Image
          avatar
          src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/logo_icon.png"
          className="Desktop_menu_dropdown_image"
        />{' '}
        {user.auth.company.profile.name}
      </span>
    );
    const {
      notifications: { company }
    } = user;
    const notifications = company.slice(0, 5);
    return (
      <div className="Desktop_container">
        <div className="Desktop_container_content">
          <Menu borderless className="Desktop_menu">
            <Menu.Item className="Desktop_menu_left">
              <Link to="/dashboard/teamlist/active">
                <Image
                  className="Desktop_menu_logo"
                  src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/logo_clear.png"
                />
              </Link>
            </Menu.Item>
            <Menu.Item position="right">
              <Popup
                trigger={
                  <Icon className="Desktop_notifications_icon" name="bell outline" size="large" />
                }
                position="bottom right"
                on="click"
                open={this.state.isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
              >
                <div className="Desktop_menu_notifications_popup">
                  <div className="Desktop_menu_notifications_popup_header">Notifications</div>
                  {notifications.map(notification => {
                    return (
                      <div
                        className="Desktop_menu_notifications_popup_content"
                        key={notification._id}
                      >
                        <div className="Desktop_menu_notifications_popup_content_message">
                          {notification.title.length > 25
                            ? `${notification.title.substring(0, 24)}...`
                            : notification.title}
                        </div>
                        <div className="Desktop_menu_notifications_popup_content_time" />
                        {moment(notification.createdAt).fromNow()}
                      </div>
                    );
                  })}
                  <div className="Desktop_menu_notifications_popup_footer">
                    <Link
                      to="/dashboard/notifications"
                      className="Desktop_menu_notifications_popup_footer_nav"
                      onClick={this.handleClose}
                    >
                      See All
                    </Link>
                  </div>
                </div>
              </Popup>
            </Menu.Item>
            <Menu.Item>
              <div className="Desktop_menu_divider" />
            </Menu.Item>
            <Menu.Item>
              <Dropdown
                trigger={trigger}
                icon={{
                  name: 'chevron down'
                }}
                className="Desktop_menu_dropdown_arrow"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={NavLink}
                    to="/dashboard/teamlist/active"
                    name="teams"
                    active={
                      location.pathname.startsWith('/dashboard/teams') ||
                      location.pathname.startsWith('/dashboard/teamlist')
                    }
                  >
                    Teams
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="/dashboard/notifications"
                    name="notifications"
                    active={location.pathname.startsWith('/dashboard/notifications')}
                  >
                    Notifications
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="/dashboard/settings"
                    name="settings"
                    active={location.pathname.startsWith('/dashboard/settings')}
                  >
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={signOut}>Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu>
          {children}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

const mapDispatchToProps = {
  signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopContainer);
