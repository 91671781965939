/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import SignIn from './sub_auth/SignIn';
// import SignUp from './sub_auth/SignUp';
import Forgot from './sub_auth/Forgot';
import Reset from './sub_auth/Reset';
import Verification from './sub_auth/Verification';

const redirect = () => {
  window.location = 'https://metriczoom.com';
};

const Auth = () => (
  <div className="Auth_background">
    <div className="Auth_container">
      <div className="Auth_form">
        <div className="Auth_form_container">
          <img
            src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/logo_clear.png"
            alt="logo"
            className="Auth_form_logo_image"
            onClick={redirect}
            onKeyDown={redirect}
          />
          <div className="Auth_form_content">
            <Switch>
              <Route exact path="/auth" render={() => <Redirect to="/auth/signin" />} />
              <Route path="/auth/signin" component={SignIn} />
              {/* <Route path="/auth/signup" component={SignUp} /> */}
              <Route path="/auth/forgot" component={Forgot} />
              <Route path="/auth/verification" component={Verification} />
              <Route path="/auth/reset" component={Reset} />
              <Route
                path="/auth/*"
                render={() => {
                  return <Redirect to="/NotFound" />;
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
      <div className="Auth_rep">
        <img
          src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/landing/rep.png"
          alt="rep"
          className="Auth_rep_image"
        />
      </div>
    </div>
  </div>
);

export default Auth;
