import React from 'react';

class Quit extends React.Component {
  render() {
    return (
      <div className="Settings_comms_container">
        <div className="Settings_comms_header">Delete Account</div>
        <div className="Settings_quit_message_container">
          Please contact{' '}
          <a href="mailto:support@metriczoom.com?Subject=Delete%20Account" target="_top">
            support@metriczoom.com
          </a>{' '}
          about closing your account. We apologize for the inconvenience.
        </div>
      </div>
    );
  }
}

export default Quit;
