import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

const placeHolder = () => (
  <div className="Payments_Placeholder_container">
    <img
      src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/credit+card.png"
      className="Payments_Placeholder_image_container"
      alt="Placeholder for empty team"
    />
    <div className="Payments_Placeholder_text_container">
      You currently have no transactions at this time.
    </div>
  </div>
);
class History extends React.Component {
  render() {
    const { detailTeam, detailBalance, detailTransactions } = this.props;
    if (!detailTeam || !detailBalance || !detailTransactions) return <div>Loading...</div>;
    const lastPayment =
      detailTransactions.charges.length > 0
        ? detailTransactions.charges[detailTransactions.charges.length - 1].amount
        : 0;
    return (
      <div className="Payments_history_container">
        <div className="Payments_history_banner_container">
          <div className="Payments_history_banner_balance">
            <div className="Payments_history_banner_balance_title">Current Balance</div>
            <div className="Payments_history_banner_balance_amount">
              ${(detailBalance.balance / 100).toLocaleString()}
            </div>
          </div>
          <div className="Payments_history_banner_bank">
            <img
              src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/stripeLogo.png"
              alt="chase"
              className="Payments_history_banner_bank_image"
            />
          </div>
          <div className="Payments_history_banner_last">
            <div className="Payments_history_banner_last_title">Last Payment</div>
            {lastPayment > 0 && (
              <div className="Payments_history_banner_last_amount">
                ${(lastPayment / 100).toLocaleString()}
              </div>
            )}
            {lastPayment === 0 && <div className="Payments_history_banner_last_amount">TBD</div>}
          </div>
        </div>
        <div className="Payments_history_list_container">
          <div className="Payments_history_list_title">
            {(detailTransactions.charges.length > 0 || detailTransactions.adjustments.length > 0) &&
              'Payments'}
          </div>
          <div className="Payments_list_grid">
            {detailTransactions.adjustments.map(adjustment => {
              return (
                <div className="Payments_list_grid_item" key={adjustment._id}>
                  <div className="Payments_list_grid_item_top_container">
                    <div className="Payments_list_grid_item_top_status success">
                      <div className="Payments_list_grid_item_top_status_text">Successful</div>
                    </div>
                    <div className="Payments_list_grid_item_top_amount">
                      ${adjustment.type === 'credit' && '-'}
                      {(adjustment.amount / 100).toLocaleString()}
                    </div>
                    <div className="Payments_list_grid_item_top_date">
                      {moment(adjustment.createdAt)
                        .utc()
                        .format('l')}
                    </div>
                    <div className="Payments_list_grid_item_top_id">
                      ID: {adjustment._id.slice(-8)}
                    </div>
                    {/* <div className="Payments_list_grid_item_top_dispute">
                      <div className="Payments_list_grid_item_top_dispute_text">Dispute</div>
                    </div> */}
                  </div>
                  <div className="Payments_list_grid_item_divider" />
                  <div className="Payments_list_grid_item_description">
                    {adjustment.description}
                  </div>
                </div>
              );
            })}
            {detailTransactions.charges.map(charge => {
              return (
                <div className="Payments_list_grid_item" key={charge.id}>
                  <div className="Payments_list_grid_item_top_container">
                    {charge.status === 'succeeded' && (
                      <div className="Payments_list_grid_item_top_status success">
                        <div className="Payments_list_grid_item_top_status_text">Successful</div>
                      </div>
                    )}
                    {charge.status === 'pending' && (
                      <div className="Payments_list_grid_item_top_status pending">
                        <div className="Payments_list_grid_item_top_status_text">Pending</div>
                      </div>
                    )}
                    {charge.status === 'pending' && (
                      <div className="Payments_list_grid_item_top_status failed">
                        <div className="Payments_list_grid_item_top_status_text">Failed</div>
                      </div>
                    )}
                    <div className="Payments_list_grid_item_top_amount">
                      ${(charge.amount / 100).toLocaleString()}
                    </div>
                    <div className="Payments_list_grid_item_top_date">
                      {moment(new Date(charge.created * 1000))
                        .utc()
                        .format('l')}
                    </div>
                    <div className="Payments_list_grid_item_top_id">ID: {charge.id.slice(-8)}</div>
                    <a
                      className="Payments_list_grid_item_top_receipt"
                      href={charge.receipt_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="Payments_list_grid_item_top_receipt_text">Receipt</div>
                    </a>
                  </div>
                  <div className="Payments_list_grid_item_divider" />
                  <div className="Payments_list_grid_item_description">{charge.description}</div>
                </div>
              );
            })}
            {detailTransactions.charges.length === 0 &&
              detailTransactions.adjustments.length === 0 &&
              placeHolder()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user, authorization },
  teamDetail: { detailTeam, detailBalance, detailTransactions }
}) => ({
  user,
  authorization,
  detailTeam,
  detailBalance,
  detailTransactions
});

export default connect(mapStateToProps)(History);
