import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notifications = ({ success, error }) => {
  if (success) {
    toast.success(success, { autoClose: 3000 });
  }
  if (error) {
    toast.error(error, { autoClose: 3000 });
  }
  return <ToastContainer />;
};

Notifications.propTypes = {
  success: PropTypes.string,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  success: state.ajax.success,
  error: state.ajax.error
});

export default connect(mapStateToProps)(Notifications);
