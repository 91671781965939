import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import notify from '../../../common/utils/notify';
import { API_URL } from '../../../common/utils/url';

class Verification extends React.Component {
  state = {
    verified: null,
    fail: false,
    email: '',
    resent: false
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const emailToken = urlParams.get('emailToken');
    const userId = urlParams.get('userId');
    if (emailToken && userId) {
      this.checkVerification(emailToken, userId);
    }
  }

  checkVerification = (emailToken, userId) => {
    const { authorization } = this.props;
    axios
      .post(
        `${API_URL}/v1/auth/email/verify`,
        {
          emailToken,
          userId
        },
        {
          headers: {
            authorization
          }
        }
      )
      .then(({ data }) => {
        if (data.success) {
          this.setState({ verified: true });
          notify('success', 'Email verified!');
        }
      })
      .catch(error => {
        notify('error', error.response.data.message);
        this.setState({ verified: false, fail: true });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resendVerification = event => {
    event.preventDefault();
    if (!this.state.email) {
      return notify('error', 'Please input an email!');
    }
    return axios
      .post(`${API_URL}/v1/auth/email/resend`, {
        email: this.state.email
      })
      .then(() => {
        this.setState({ resent: true });
        notify('success', 'Verification email resent!');
      })
      .catch(error => {
        notify('error', error.response.data.message);
        this.setState({ resent: false });
      });
  };

  render() {
    const { verified, resent } = this.state;
    if (verified) {
      return (
        <div className="SignIn_container">
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">
              Email Verified! <Link to="signin">Sign In</Link>
            </h2>
          </div>
        </div>
      );
    }
    if (resent) {
      return (
        <div className="SignIn_container">
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">
              Please check your email! <Link to="signin">Sign In</Link>
            </h2>
          </div>
        </div>
      );
    }
    return (
      <div className="SignIn_container">
        <form onSubmit={this.resendVerification}>
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">
              {this.state.fail ? 'Email verifiation failed!' : 'Your email is not verified!'}
            </h2>
            <div className="SignIn_form_input_container">
              <div className="SignIn_form_input_email_container">
                <div className="SignIn_form_input_email_divider" />
                <input
                  className="SignIn_form_input_email"
                  placeholder="Email"
                  value={this.state.email}
                  type="email"
                  name="email"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="Forgot_form_submit_container">
              <button type="submit" onClick={this.resendVerification}>
                Resend Verification Email
              </button>
            </div>
            <div className="SignIn_helper_container">
              <span>Back to </span>
              <Link to="signin">Sign In</Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({ isAuthenticated });

export default connect(mapStateToProps)(Verification);
