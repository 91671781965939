import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import notify from '../../../common/utils/notify';
import { API_URL } from '../../../common/utils/url';

export default class Reset extends React.Component {
  state = {
    verified: null,
    pass1: '',
    pass2: '',
    changed: false
  };

  componentDidMount() {
    this.checkToken();
  }

  checkToken = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const resetToken = urlParams.get('token');
    axios
      .get(`${API_URL}/v1/auth/forgot/change/${resetToken}`)
      .then(({ data }) => {
        if (data.success) {
          this.setState({ verified: true });
        }
      })
      .catch(error => {
        notify('error', error.response.data.message);
        this.setState({ verified: false });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changePassword = event => {
    event.preventDefault();
    if (this.state.pass1 !== this.state.pass2 || !this.state.pass1) {
      return notify('error', 'The passwords do not match!');
    }
    const urlParams = new URLSearchParams(this.props.location.search);
    const resetToken = urlParams.get('token');
    return axios
      .post(`${API_URL}/v1/auth/forgot/change`, {
        password: this.state.pass1,
        token: resetToken
      })
      .then(() => {
        this.setState({ changed: true });
        notify('success', 'Password changed!');
      })
      .catch(error => {
        notify('error', error.response.data.message);
      });
  };

  render() {
    const { verified, changed, pass1, pass2 } = this.state;
    if (!verified) {
      return (
        <div className="SignIn_container">
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">
              Incorrect token! <Link to="signin">Sign In</Link>
            </h2>
          </div>
        </div>
      );
    }
    if (verified && changed) {
      return (
        <div className="SignIn_container">
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">
              Password successfully changed! <Link to="signin">Sign In</Link>
            </h2>
          </div>
        </div>
      );
    }
    return (
      <div className="SignIn_container">
        <form onSubmit={this.changePassword}>
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">Password Reset Form</h2>
            <div className="SignIn_form_input_container">
              <div className="SignIn_form_input_email_container">
                <div className="SignIn_form_input_email_divider" />
                <input
                  className="SignIn_form_input_email"
                  placeholder="Password"
                  type="password"
                  name="pass1"
                  value={pass1}
                  onChange={this.onChange}
                />
              </div>
              <div className="SignIn_form_input_password_container">
                <div className="SignIn_form_input_password_divider" />
                <input
                  className="SignIn_form_input_password"
                  placeholder="Password Confirmation"
                  type="password"
                  name="pass2"
                  value={pass2}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="SignIn_form_submit_container">
              <button type="submit" onClick={this.changePassword}>
                Change Password
              </button>
            </div>
            <div className="SignIn_helper_container">
              <span>Did you remember your password? </span>
              <Link to="signin">&nbsp; Sign In</Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
