import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Dropdown, Grid, Label, Modal } from 'semantic-ui-react';
// Local
import notify from '../../../../common/utils/notify';
import { createTeam } from '../../../../reducers/sub_reducers/teamCreate';
import { getTeamList } from '../../../../reducers/sub_reducers/teamList';
import { API_URL } from '../../../../common/utils/url';
// Components
import TeamBanner from '../../common/banners/TeamBanner';

class Active extends React.Component {
  state = { modalState: false, selectedTeam: null };

  deleteTeam = team => {
    const { authorization, user, getTeamList } = this.props;
    axios
      .delete(`${API_URL}/v1/team/${team._id}`, {
        headers: {
          authorization
        }
      })
      .then(() => {
        getTeamList(user.auth.company._id, authorization);
        notify('success', 'Team set for deletion!');
        this.closeModal();
      })
      .catch(error => {
        notify('error', error.response.data.message);
        console.error(error);
      });
  };

  selectTeam = team => {
    this.setState({ selectedTeam: team, modalState: true });
  };

  closeModal = () => {
    this.setState({ modalState: false });
  };

  render() {
    const { authorization, createTeam, teamsActive, location, teamsCompany } = this.props;

    // Get not deleted teams and replace the middle grid thing
    if (!teamsActive) return <div>Loading</div>;
    const visibleTeams = teamsActive.reduce((acc, team) => {
      if (!team.deletedAt) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return (
      <React.Fragment>
        {visibleTeams > 0 ? (
          <Grid columns={3} container doubling stackable>
            {visibleTeams === 1 && <Grid.Column></Grid.Column>}
            {teamsActive.map(team => {
              return (
                <Grid.Column key={team._id} className="TeamList_sub_grid_padding">
                  <TeamBanner company={teamsCompany} team={team} location={location} />
                  <div className="Active_menu_container">
                    <Link to={`/dashboard/create/${team._id}/recruitment`}>
                      <div className="Active_menu_edit">
                        <img
                          src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamList/pen.svg"
                          alt="edit"
                          className="Active_menu_edit_icon"
                        />
                      </div>
                    </Link>
                    <div className="Active_menu_more">
                      <Dropdown icon="ellipsis vertical" className="Active_menu_more_icon">
                        <Dropdown.Menu id="Active_menu_more_dropdown">
                          <Dropdown.Item
                            as={NavLink}
                            to={`/dashboard/teams/${team._id}/manage/chooserep`}
                            className="Active_menu_more_dropdown"
                          >
                            Manage Team
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={NavLink}
                            to={`/dashboard/teams/${team._id}/integrations`}
                          >
                            Integrations
                          </Dropdown.Item>
                          <Dropdown.Item as={NavLink} to={`/dashboard/teams/${team._id}/payments`}>
                            Payments
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="Active_menu_more_dropdown_delete"
                            onClick={() => {
                              this.selectTeam(team);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {team.expiration && team.expiration > moment().format() && (
                      <Label as="a" color="yellow" ribbon className="Active_menu_delete_ribbon">
                        Will be deleted on {moment(team.expiration).format('lll')}
                      </Label>
                    )}
                    {team.expiration && team.expiration < moment().format() && (
                      <Label as="a" color="red" ribbon className="Active_menu_delete_ribbon">
                        Was deleted on {moment(team.expiration).format('lll')}
                      </Label>
                    )}
                  </div>
                </Grid.Column>
              );
            })}
            <Modal open={this.state.modalState} onClose={this.closeModal} size="mini" closeIcon>
              <Modal.Header>Oh No! You are about to cancel this team!</Modal.Header>
              <Modal.Content>
                <p> Your ZoomReps may not be available at a later date. </p>
                <p>
                  Your team will be marked for deletion 1 month from now. Are you sure you want to
                  delete this team?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  onClick={() => {
                    this.deleteTeam(this.state.selectedTeam);
                  }}
                >
                  Yes, Cancel
                </Button>
                <Button positive onClick={this.closeModal}>
                  No, Don&apos;t Cancel
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid>
        ) : (
          <div className="List_empty_filler">
            <img
              src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/sadDog.png"
              alt="Sad Face"
              className="List_empty_filler_image"
            />
            <p className="List_empty_filler_top">There are no active teams currently.</p>
            <p className="List_empty_filler_bottom">Please create a new one.</p>
            <button
              type="button"
              onClick={() => {
                createTeam(authorization);
              }}
              name="create"
              className="List_empty_filler_create"
            >
              Let&apos;s Start!
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  auth: { authorization, user },
  teamList: { teamsActive, teamsCompany }
}) => ({
  authorization,
  teamsActive,
  teamsCompany,
  user
});

const mapDispatchToProps = {
  createTeam,
  getTeamList
};

export default connect(mapStateToProps, mapDispatchToProps)(Active);
