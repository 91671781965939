import React from 'react';
import { Rating } from 'semantic-ui-react';
import CompanyReviews from '../CompanyReviews';

class DetailTeamBanner extends React.Component {
  render() {
    const { team } = this.props;
    if (!team) return <div>Loading...</div>;
    return (
      <div className="Custom_Team_banner">
        <div
          className="Custom_Team_banner_top_background"
          style={{ backgroundColor: team.profile.questions.bannerColor }}
        >
          <div className="Custom_Team_banner_top_name">{team.profile.questions.name}</div>
        </div>
        <div className="Custom_Team_banner_center_header">
          <div className="Custom_Team_banner_center_header_container">
            {/* <Image src={team.profile.company.profile.logo} fluid /> */}
            <img
              src={team.profile.company.profile.logo}
              className="Team_banner_center_header_logo"
              alt="Company specific"
            />
            <div className="Team_banner_center_header_price">
              ${team.profile.questions.leadPrice ? team.profile.questions.leadPrice / 100 : 0}
            </div>
            <div className="Custom_Team_banner_center_header_text">per Lead</div>
            <div className="Custom_Team_banner_center_header_rating_container">
              <Rating
                className="Team_banner_center_header_rating_rating"
                maxRating={5}
                defaultRating={team.profile.company.profile.reviews.reduce((acc, review) => {
                  return acc + review.rating;
                }, 0)}
                icon="star"
                size="large"
                disabled
              />
              <CompanyReviews company={team.profile.company} />
            </div>
          </div>
        </div>
        <div className="Custom_Team_banner_description_container">
          <p className="Custom_Team_banner_description_text">
            {team.profile.questions.description}
          </p>
        </div>
        <div className="Custom_Team_banner_footer_container">
          <div className="Custom_Team_banner_footer_title">Download the mobile app and apply!</div>
          <div className="Custom_Team_banner_footer_download_buttons">
            <img
              alt="iOS Download"
              className="Custom_Team_banner_footer_download_buttons_images"
              src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/googleplay.png"
            />
            <img
              alt="Android Download"
              className="Custom_Team_banner_footer_download_buttons_images"
              src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/applestore.png"
            />
          </div>
          <div className="Custom_Team_banner_footer_text">
            Powered by{' '}
            <span>
              <img
                src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/logo_clear.png"
                alt="mz stamp"
                className="Custom_Team_banner_footer_text_logo"
              />
            </span>{' '}
          </div>
        </div>
      </div>
    );
  }
}

export default DetailTeamBanner;
