import React from 'react';
import { Rating } from 'semantic-ui-react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import CompanyReviews from '../CompanyReviews';

const stepEnums = {
  0: 'recruitment',
  1: 'compensation',
  2: 'customization',
  3: 'customization',
  4: 'publication'
};

class TeamBanner extends React.Component {
  state = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    redirect: false
  };

  componentDidMount() {
    this.startTimer();
    this.interval = setInterval(this.startTimer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startTimer = () => {
    const { team } = this.props;
    const currentDate = moment(new Date());
    const teamDate = moment(team.createdAt);
    this.setState({
      days: currentDate.diff(teamDate, 'days'),
      hours: currentDate.diff(teamDate, 'hours') % 24,
      minutes: currentDate.diff(teamDate, 'minutes') % 60,
      seconds: currentDate.diff(teamDate, 'seconds') % 60
    });
  };

  redirect = event => {
    event.stopPropagation();
    this.setState({
      redirect: true
    });
  };

  render() {
    const {
      company,
      team,
      location: { pathname }
    } = this.props;
    if (
      this.state.redirect &&
      !pathname.includes('teams') &&
      (!pathname.includes('saved') || team.profile.questions.currentStep > 3)
    ) {
      return <Redirect push to={`/dashboard/teams/${team._id}/manage/chooserep`} />;
    }
    if (this.state.redirect && !pathname.includes('teams') && !pathname.includes('active')) {
      return (
        <Redirect
          push
          to={`/dashboard/create/${team._id}/${stepEnums[team.profile.questions.currentStep]}`}
        />
      );
    }
    if (!team) {
      return <div>Loading...</div>;
    }
    return (
      <div className="Team_banner" onClick={this.redirect} onKeyDown={this.handleKeyDown}>
        <div
          className="Team_banner_top_background"
          style={{ backgroundColor: team.profile.questions.bannerColor }}
        >
          <div className="Team_banner_top_name">
            {team.profile.questions.name.length > 15
              ? `${team.profile.questions.name.substring(0, 14)}...`
              : team.profile.questions.name}
          </div>
        </div>
        <div className="Team_banner_center_header">
          <div className="Team_banner_center_header_container">
            <img
              src={team.profile.company.profile.logo}
              className="Team_banner_center_header_logo"
              alt="Company specific"
            />
            <div className="Team_banner_center_header_price">
              ${team.profile.questions.leadPrice ? team.profile.questions.leadPrice / 100 : 0}
            </div>
            <div className="Team_banner_center_header_text">per Lead</div>
            <div className="Team_banner_center_header_rating_container">
              <Rating
                className="Team_banner_center_header_rating_rating"
                maxRating={5}
                defaultRating={team.profile.company.profile.reviews.reduce((acc, review) => {
                  return acc + review.rating;
                }, 0)}
                icon="star"
                size="large"
                disabled
              />
              <CompanyReviews company={company} />
            </div>
          </div>
        </div>
        <div className="Team_banner_description_container">
          <p className="Team_banner_description_text">{team.profile.questions.description}</p>
        </div>
        <div className="Team_banner_footer_container">
          <p className="Team_banner_footer_title">Zooming For</p>
          <div className="Team_banner_footer_stats_container">
            <div className="Team_banner_footer_stats_one">
              <div className="Team_banner_footer_stats_one_title">Days</div>
              <div className="Team_banner_footer_stats_one_stat">{this.state.days}</div>
            </div>
            <div className="Team_banner_footer_stats_two">
              <div className="Team_banner_footer_stats_two_title">Hours</div>
              <div className="Team_banner_footer_stats_two_stat">{this.state.hours}</div>
            </div>
            <div className="Team_banner_footer_stats_three">
              <div className="Team_banner_footer_stats_three_title">Mins</div>
              <div className="Team_banner_footer_stats_three_stat">{this.state.minutes}</div>
            </div>
            <div className="Team_banner_footer_stats_four">
              <div className="Team_banner_footer_stats_four_title">Secs</div>
              <div className="Team_banner_footer_stats_four_stat">{this.state.seconds}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamBanner;
