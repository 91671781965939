import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import { Modal, Rating } from 'semantic-ui-react';
import axios from 'axios';
import { getTeamJobs } from '../../../reducers/sub_reducers/teamDetail';
import { API_URL } from '../../../common/utils/url';
import notify from '../../../common/utils/notify';

class RateRep extends Component {
  state = {
    rating: 0,
    review: '',
    showModal: false
  };

  handleRatingChange = (event, data) => {
    this.setState({ rating: data.rating });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  rate = event => {
    event.preventDefault();
    const { rep, authorization, detailTeam, getTeamJobs } = this.props;
    const { rating, review } = this.state;
    if (!rating || !review) {
      return notify('error', 'You must fill every field!');
    }
    return axios
      .post(
        `${API_URL}/v1/user/${rep._id}/review`,
        {
          rating,
          content: review
          // phone,
          // logo
        },
        {
          headers: {
            authorization
          }
        }
      )
      .then(() => {
        this.setState({ rating: 0, review: '', showModal: false });
        getTeamJobs(authorization, detailTeam._id);
        notify('success', 'You rated the rep!');
      })
      .catch(error => {
        notify('error', error.response.data.message);
        console.error(error);
      });
  };

  render() {
    const { rep } = this.props;
    if (!rep) return <div>Loading...</div>;
    const ratingTrigger = (
      <Rating
        className="TeamView_rep_summary_description_rating_rating"
        maxRating={5}
        defaultRating={rep.profile.reviews.reduce((acc, review) => {
          return acc + review.rating;
        }, 0)}
        icon="star"
        size="large"
        onClick={() => this.setState({ showModal: true })}
      />
    );
    return (
      <Modal
        trigger={ratingTrigger}
        closeIcon
        onClose={this.closeModal}
        open={this.state.showModal}
        size="mini"
      >
        <Modal.Header>Rate {rep.profile.firstName}!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Rating
              className="RateRep_rating"
              maxRating={5}
              icon="star"
              size="huge"
              onRate={this.handleRatingChange}
              rating={this.state.rating}
            />
          </Modal.Description>
          <Modal.Description>
            <form onSubmit={this.rate}>
              <input
                className="RateRep_input"
                placeholder="Review"
                name="review"
                value={this.state.review}
                onChange={this.onChange}
              />
            </form>
          </Modal.Description>
          <Modal.Description>
            <button className="RateRep_submit" type="submit" onClick={this.rate}>
              Rate Rep
            </button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = ({ auth: { user, authorization }, teamDetail: { detailTeam } }) => ({
  user,
  detailTeam,
  authorization
});

const mapDispatchToProps = {
  getTeamJobs
};

export default connect(mapStateToProps, mapDispatchToProps)(RateRep);
