import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { history } from '../../../../store/configureStore';
import { editCreateTeam } from '../../../../reducers/sub_reducers/teamCreate';

class Recruitment extends React.Component {
  state = {
    selectedItems: []
  };

  componentDidMount() {
    const { createdTeam } = this.props;
    this.setState({
      selectedItems: createdTeam.profile.questions.industries
    });
  }

  toggleItem = () => {
    const {
      target: { value }
    } = event;
    this.setState(prevState => {
      const newArr = [...prevState.selectedItems];
      if (!newArr.includes(value)) newArr.push(value);
      else newArr.splice(newArr.indexOf(value), 1);
      return { selectedItems: newArr };
    });
  };

  updateTeam = () => {
    const { selectedItems } = this.state;
    const teamId = this.props.createdTeam._id;
    this.props.editCreateTeam(0, teamId, { industries: selectedItems });
  };

  goToTeams = () => {
    history.push('/dashboard/teamlist/active');
  };

  render() {
    const { createdTeam } = this.props;
    if (!createdTeam) return <div>loading...</div>;

    const industries = [
      'Enterprise (10,000+ Employees)',
      'Mid-Market (250-999 Employees)',
      'SMB (<250 Employees)'
    ];
    return (
      <React.Fragment>
        <Container className="TeamCreate_Container">
          <Grid>
            <Grid.Row className="Recruitment_Container_Header">
              <div className="Recruitment_Header">
                <div>
                  Please answer the following questions so we can match you to the best ZoomReps
                </div>
              </div>
            </Grid.Row>
            <div className="Recruitment_Container_Body">
              <Grid.Row className="Recruitment_Container_Description">
                <div className="Recruitment_Header">
                  <div className="Recruitment_Questions_Header">
                    Q: Who do you sell your products to?
                    <span className="Recruitment_Questions_Header_Extra">
                      &nbsp;&nbsp;choose all that apply
                    </span>
                  </div>
                </div>
              </Grid.Row>
              <Grid.Row className="Recruitment_Container_ScrollSection">
                <div>
                  {industries.map((el, key) => (
                    <button
                      type="button"
                      value={el}
                      // eslint-disable-next-line react/no-array-index-key
                      key={key}
                      className={`Recruitment_Container_Industries ${this.state.selectedItems.includes(
                        el
                      ) && 'Highlighted'}`}
                      onClick={this.toggleItem}
                      onKeyDown={this.toggleItem}
                    >
                      {el}
                    </button>
                  ))}
                </div>
              </Grid.Row>
              {/* <Grid.Row>
                <div className="Recruitment_Container_Questions">
                  <div className="Recruitment_Container_Questions_Title">Question: 1/6</div>
                  <Icon
                    className="Recruitment_Container_Questions_Icon"
                    name="angle right"
                    size="large"
                  />
                </div>
              </Grid.Row> */}
            </div>
            <Grid.Row className="Customization_Container_Bottom_Nav">
              <button
                className="Recruitment_Bottom_Nav_Button"
                type="button"
                onClick={this.goToTeams}
              >
                Back
              </button>
              <button
                className={`Customization_Button_Next ${this.state.selectedItems.length > 0 &&
                  'ready'}`}
                type="button"
                onClick={this.updateTeam}
              >
                Next
              </button>
            </Grid.Row>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, teamCreate: { createdTeam } }) => ({
  authorization,
  createdTeam
});

const mapDispatchToProps = {
  editCreateTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(Recruitment);
