/* eslint-disable max-len */
import React from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Container, Icon } from 'semantic-ui-react';
import { getCreatedTeam } from '../../../reducers/sub_reducers/teamCreate';
import Recruitment from './sub_team_create/Recruitment';
import Compensation from './sub_team_create/Compensation';
import Customization from './sub_team_create/Customization';
import Publication from './sub_team_create/Publication';

class TeamCreate extends React.Component {
  state = {
    fetched: false
  };

  componentDidMount() {
    this.fetchTeam();
  }

  fetchTeam() {
    const { getCreatedTeam, authorization, match } = this.props;
    getCreatedTeam(authorization, match.params.teamId).then(() => {
      this.setState({ fetched: true });
    });
  }

  render() {
    const {
      createdTeam,
      location: { pathname }
    } = this.props;
    if (!createdTeam || !this.state.fetched) {
      return <div>Loading</div>;
    }
    const { currentStep } = createdTeam.profile.questions;

    return (
      <div className="TeamCreate_overall_container">
        <div className="Detail_nav">
          <div className="Detail_nav_left">
            <div className="Detail_nav_left_location">
              <Link to="/dashboard/teamlist/active">
                <Icon name="chevron left" />{' '}
                <span className="Detail_nav_left_link_text">Back to Teams </span>
              </Link>
            </div>
          </div>
        </div>
        <Container className="Sidebar_Container">
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} className="TeamCreate_Sidebar_Container">
                <div>
                  <div id={`vertical_dotted_line${currentStep}`} />
                  {currentStep > 0 ? (
                    <Link to={`${this.props.match.url}/recruitment`}>
                      <div
                        className={`TeamCreate_Sidebar_Step ${pathname.endsWith('recruitment') &&
                          'active'}`}
                      >
                        <img
                          alt="sidebarimg"
                          className="TeamCreate_Sidebar_icon_1"
                          src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamCreate/zoom.png"
                        />
                        <div
                          className={`TeamCreate_Sidebar_label ${pathname.endsWith('recruitment') &&
                            'active'}`}
                        >
                          <div className="TeamCreate_Sidebar_Title">STEP 1</div>
                          <div className="TeamCreate_Sidebar_Description">Recruitment</div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div
                      className={`TeamCreate_Sidebar_Step ${pathname.endsWith('recruitment') &&
                        'active'}`}
                    >
                      <img
                        alt="sidebarimg"
                        className="TeamCreate_Sidebar_icon_1"
                        src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamCreate/zoom.png"
                      />
                      <div
                        className={`TeamCreate_Sidebar_label ${pathname.endsWith('recruitment') &&
                          'active'}`}
                      >
                        <div className="TeamCreate_Sidebar_Title">STEP 1</div>
                        <div className="TeamCreate_Sidebar_Description">Recruitment</div>
                      </div>
                    </div>
                  )}
                  {currentStep >= 1 && (
                    <Link to={`${this.props.match.url}/compensation`}>
                      <div
                        className={`TeamCreate_Sidebar_Step ${pathname.endsWith('compensation') &&
                          'active'}`}
                      >
                        <img
                          alt="sidebarimg"
                          className="TeamCreate_Sidebar_icon_2"
                          src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamCreate/money.png"
                        />

                        <div
                          className={`TeamCreate_Sidebar_label ${pathname.endsWith(
                            'compensation'
                          ) && 'active'}`}
                        >
                          <div className="TeamCreate_Sidebar_Title">STEP 2</div>
                          <div className="TeamCreate_Sidebar_Description">Compensation</div>
                        </div>
                      </div>
                    </Link>
                  )}
                  {currentStep >= 2 && (
                    <Link to={`${this.props.match.url}/customization`}>
                      <div
                        className={`TeamCreate_Sidebar_Step ${pathname.endsWith('customization') &&
                          'active'}`}
                      >
                        <img
                          alt="sidebarimg"
                          className="TeamCreate_Sidebar_icon_3"
                          src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamCreate/gear.png"
                        />
                        <div
                          className={`TeamCreate_Sidebar_label ${pathname.endsWith(
                            'customization'
                          ) && 'active'}`}
                        >
                          <div className="TeamCreate_Sidebar_Title">STEP 3</div>
                          <div className="TeamCreate_Sidebar_Description">Customization</div>
                        </div>
                      </div>
                    </Link>
                  )}
                  {currentStep >= 3 && (
                    <Link to={`${this.props.match.url}/publication`}>
                      <div
                        className={`TeamCreate_Sidebar_Step ${pathname.endsWith('publication') &&
                          'active'}`}
                      >
                        <img
                          alt="sidebarimg"
                          className="TeamCreate_Sidebar_icon_4"
                          src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamCreate/cloud.png"
                        />
                        <div
                          className={`TeamCreate_Sidebar_label ${pathname.endsWith('publication') &&
                            'active'}`}
                        >
                          <div className="TeamCreate_Sidebar_Title">STEP 4</div>
                          <div className="TeamCreate_Sidebar_Description">Publication</div>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={10}>
                <Switch>
                  <Route
                    exact
                    path={`${this.props.match.url}/`}
                    render={() => {
                      return <Redirect to="/NotFound" />;
                    }}
                  />
                  <Route path={`${this.props.match.url}/recruitment`} component={Recruitment} />
                  <Route path={`${this.props.match.url}/compensation`} component={Compensation} />
                  <Route path={`${this.props.match.url}/customization`} component={Customization} />
                  <Route path={`${this.props.match.url}/publication`} component={Publication} />
                  <Route
                    path={`${this.props.match.url}/*`}
                    render={() => {
                      return <Redirect to="/NotFound" />;
                    }}
                  />
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, teamCreate: { createdTeam } }) => ({
  authorization,
  createdTeam
});

const mapDispatchToProps = {
  getCreatedTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCreate);
