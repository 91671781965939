import React from 'react';
import { Link } from 'react-router-dom';
import { Rating, Modal, Icon, Button, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Page, Document } from 'react-pdf';
import RepReviews from '../RepReviews';

class RepBanner extends React.Component {
  state = {
    showModal: false,
    // numPages: null,
    pageNumber: 1
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  onDocumentLoadSuccess = () => {
    // this.setState({ numPages });
  };

  render() {
    const { application, addTempRep, rejectApplication, detailTeam } = this.props;
    if (!application || !detailTeam) return <div>Loading...</div>;
    const { createdAt, profile } = application.rep;
    const { firstName, lastName, profilePicture, questions } = profile;
    const { bio, video } = questions;
    const { pageNumber } = this.state;
    const trigger = (
      <button type="button" className="Rep_banner_buttons_reject">
        Reject
      </button>
    );
    const requirementTrigger = (
      <button
        type="button"
        className="Rep_banner_buttons_select"
        onClick={() => {
          this.setState({ showModal: true });
        }}
      >
        Select
      </button>
    );
    const addRepButton = (
      <button
        type="button"
        className="Rep_banner_buttons_select"
        onClick={() => {
          addTempRep(application);
        }}
      >
        Select
      </button>
    );
    const currentDate = moment(new Date());
    const teamDate = moment(createdAt);
    const daysSince = currentDate.diff(teamDate, 'days');
    return (
      <div className="Rep_banner_container">
        <div className="Rep_banner_top_container">
          {daysSince < 8 && (
            <Label className="Rep_banner_ribbon" corner="left" size="large">
              <div className="Rep_banner_ribbon_text">NEW</div>
            </Label>
          )}
          <img src={profilePicture} alt="profile" className="Rep_banner_top_image" />
          <div className="Rep_banner_top_description">
            <div className="Rep_banner_top_description_name">{`${firstName} ${lastName}`} </div>
            <div className="Rep_banner_top_description_since">
              Rep Since{' '}
              {moment(createdAt)
                .utc()
                .format('l')}
            </div>
            <div className="Rep_banner_top_description_rating_container">
              <Rating
                className="Rep_banner_top_description_rating_rating"
                maxRating={5}
                defaultRating={profile.reviews.reduce((acc, review) => {
                  return acc + review.rating;
                }, 0)}
                icon="star"
                size="large"
                disabled
              />
              <div className="Rep_banner_RepReview_container">
                <RepReviews rep={application.rep} />
              </div>
            </div>
            <Modal
              trigger={
                <div className="Rep_banner_top_description_more_container">More Details</div>
              }
              size="small"
              className="Rep_Banner_moreinfo_container"
              closeIcon
            >
              <Modal.Content>
                <div className="RepReviews_container">
                  <div className="RepReviews_container_header">
                    <img
                      src={application.rep.profile.profilePicture}
                      className="RepReviews_container_header_picture"
                      alt="rep profile"
                    />
                    <div className="RepReviews_container_header_description">
                      <div className="RepReviews_container_header_description_name">
                        {application.rep.profile.firstName} {application.rep.profile.lastName}
                      </div>
                      <div className="RepReviews_container_header_description_since">
                        Rep Since{' '}
                        {moment(application.rep.createdAt)
                          .utc()
                          .format('MMMM Do YYYY')}
                      </div>
                      <div className="RepReviews_container_header_rating">
                        <div className="RepReviews_container_header_rating_number">
                          {application.rep.profile.reviews
                            .reduce((acc, review) => {
                              return acc + review.rating;
                            }, 0)
                            .toFixed(1)}
                        </div>
                        <Rating
                          className="RepReviews_container_header_rating_rating"
                          maxRating={5}
                          defaultRating={application.rep.profile.reviews.reduce((acc, review) => {
                            return acc + review.rating;
                          }, 0)}
                          icon="star"
                          size="large"
                          disabled
                        />
                        <div className="RepBanner_container_header_rating_review">
                          {`${application.rep.profile.reviews.length} reviews`}
                        </div>
                      </div>
                    </div>
                    <div className="Rep_Banner_moreinfo_content">
                      <div className="Rep_Banner_moreinfo_content_bio">
                        {application.rep.profile.questions.bio}
                      </div>
                      <div className="Rep_Banner_moreinfo_content_resume">
                        {' '}
                        <Document
                          file={application.rep.profile.questions.documents[0].url}
                          onLoadSuccess={this.onDocumentLoadSuccess}
                          onLoadError={console.error}
                          width={200}
                        >
                          <Page pageNumber={pageNumber} width={200} />
                        </Document>
                        <a
                          className="Resume_download_link"
                          href={application.rep.profile.questions.documents[0].url}
                          download
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <div className="Rep_banner_description_container">{bio}</div>
        <div className="Rep_banner_video_container">
          <ReactPlayer url={video} width="20.33rem" height="10.33rem" controls />
        </div>
        <div className="Rep_banner_buttons_group">
          <Modal trigger={trigger} size="mini" closeIcon className="Modal_fire_rep">
            <Modal.Header>Are you sure you want to reject this rep?</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <button
                  className="Detail_manage_rep_chosen_fire_button"
                  type="button"
                  onClick={() => {
                    rejectApplication(application);
                  }}
                >
                  Reject Rep
                </button>
              </Modal.Description>
            </Modal.Content>
          </Modal>
          {(!detailTeam.profile.company.wallet.stripeId ||
            !detailTeam.profile.company.integrations ||
            !detailTeam.profile.company.integrations.profileId) && (
            <Modal
              trigger={requirementTrigger}
              size="mini"
              open={this.state.showModal}
              closeIcon
              onClose={this.closeModal}
            >
              <Modal.Header style={{ textAlign: 'center' }}>
                Please first integrate your CRM and Bank!
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <div style={{ textAlign: 'center' }}>
                    {(!detailTeam.profile.company.integrations ||
                      !detailTeam.profile.company.integrations.profileId) && (
                      <Link to="/dashboard/settings/integrations">
                        <Button
                          size="small"
                          href="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/documents/MetricZoom+Terms+of+Use.docx"
                          download
                          className="Rep_banner_modal_button_left"
                        >
                          <Icon name="download" />
                          Integrate CRM
                        </Button>
                      </Link>
                    )}
                    {!detailTeam.profile.company.wallet.stripeId && (
                      <Link to="/dashboard/settings/payment">
                        <Button
                          size="small"
                          href="ht tps://mz-public-bucket.s3.us-east-2.amazonaws.com/images/documents/MetricZoom+Terms+of+Use.docx"
                          download
                          className="Rep_banner_modal_button_right"
                          color="green"
                        >
                          <Icon name="download" />
                          Add Payment
                        </Button>
                      </Link>
                    )}
                  </div>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          )}
          {detailTeam.profile.company.wallet.stripeId &&
            detailTeam.profile.company.integrations &&
            detailTeam.profile.company.integrations.profileId &&
            addRepButton}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ teamDetail: { detailTeam } }) => ({
  detailTeam
});

export default connect(mapStateToProps)(RepBanner);
