import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ajax from './sub_reducers/ajax';

import { authReducer as auth } from './sub_reducers/auth';
import { settingsReducer as settings } from './sub_reducers/settings';
import { teamCreateReducer as teamCreate } from './sub_reducers/teamCreate';
import { teamDetailReducer as teamDetail } from './sub_reducers/teamDetail';
import { teamListReducer as teamList } from './sub_reducers/teamList';

export default history =>
  combineReducers({
    ajax,
    auth,
    settings,
    teamCreate,
    teamDetail,
    teamList,
    router: connectRouter(history)
  });
