import React from 'react';
import { connect } from 'react-redux';
import { Input, Modal } from 'semantic-ui-react';
import moment from 'moment';
import { fireRep, getTeamDetail } from '../../../../../reducers/sub_reducers/teamDetail';
import RateRep from '../../../common/RateRep';
import RepReviews from '../../../common/RepReviews';

class TeamView extends React.Component {
  state = {};

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  fireRep = job => {
    const { authorization, fireRep } = this.props;
    fireRep(authorization, job);
  };

  render() {
    // const options = [
    //   { key: 1, text: 'Choice 1', value: 1 },
    //   { key: 2, text: 'Choice 2', value: 2 },
    //   { key: 3, text: 'Choice 3', value: 3 }
    // ];
    const { detailTeam, detailJobs, authorization } = this.props;
    if (!detailTeam || !detailJobs) return <div>Loading</div>;
    const filteredJobs = detailJobs.filter(job => {
      return job.status === 'hired';
    });
    return (
      <div className="TeamView_container">
        <div className="TeamView_filter_container">
          <div className="TeamView_filter_search">
            <Input
              className="Team_view_filter_search_element"
              fluid
              icon="search"
              placeholder="Search..."
            />
          </div>
          {/* <Dropdown className="TeamView_filter_rank" fluid options={options} selection placeholder="Filter by" /> */}
        </div>
        <div className="TeamView_rep_container">
          {filteredJobs.length > 0 &&
            filteredJobs.map(job => {
              const hiredDate = moment(
                job.modelHistory.find(date => {
                  return date.title === 'hired';
                }).eventDate
              );
              const totalDays = moment(new Date()).diff(hiredDate, 'days');
              const trigger = (
                <button type="button" className="TeamView_rep_instance_chosen_remove">
                  Remove
                </button>
              );

              return (
                <div className="TeamView_rep_instance_container" key={job._id}>
                  <div className="TeamView_rep_summary_container">
                    <img
                      src={job.rep.profile.profilePicture}
                      alt="profile"
                      className="TeamView_rep_summary_image"
                    />
                    <div className="TeamView_rep_summary_description">
                      <div className="TeamView_rep_summary_description_name">
                        {job.rep.profile.firstName}
                      </div>
                      <div className="TeamView_rep_summary_description_rating_container">
                        <RateRep rep={job.rep} authorization={authorization} />
                        <span className="TeamView_rep_summary_description_rating_reviews">
                          <RepReviews rep={job.rep} />
                        </span>
                      </div>
                      <div className="TeamView_rep_summary_description_since">
                        Hired Date: {hiredDate.utc().format('l')} ({totalDays} days)
                      </div>
                    </div>
                  </div>
                  <div className="TeamView_rep_instance_button_container">
                    <Modal trigger={trigger} size="mini" closeIcon className="Modal_fire_rep">
                      <Modal.Header>Are you sure you want to fire this rep?</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <button
                            className="Detail_manage_rep_chosen_fire_button"
                            type="button"
                            onClick={() => {
                              this.fireRep(job);
                            }}
                          >
                            Fire Rep
                          </button>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                    {/* <button type="button" className="TeamView_rep_instance_chosen_detail">
                    View Details
                  </button> */}
                  </div>
                </div>
              );
            })}
          {filteredJobs.length === 0 && (
            <div className="TeamView_Placeholder_container">
              <img
                src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/placeholder.png"
                className="TeamView_Placeholder_image_container"
                alt="Placeholder for empty team"
              />
              <div className="TeamView_Placeholder_text_container">
                You currently have no one on your team... please hire some reps!
              </div>
            </div>
          )}
        </div>
        {/* <div className="TeamView_paginator">
          <button type="button" className="TeamView_paginator_previous">Previous</button>
          <button type="button" className="TeamView_paginator_number">1</button>
          <button type="button" className="TeamView_paginator_number">2</button>
          <button type="button" className="TeamView_paginator_next">Next</button>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = ({
  auth: { user, authorization },
  teamDetail: { detailTeam, detailJobs }
}) => ({
  user,
  authorization,
  detailTeam,
  detailJobs
});

const mapDispatchToProps = {
  fireRep,
  getTeamDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamView);
