import React from 'react';
import { connect } from 'react-redux';
import { integrateSalesforce } from '../../../../reducers/sub_reducers/settings';
import { WEB_URL } from '../../../../common/utils/url';

class Integrations extends React.Component {
  render() {
    const { integrateSalesforce, settingsUser, authorization } = this.props;
    if (!settingsUser) return <div>Loading...</div>;
    const urlParams = new URLSearchParams(this.props.location.search);
    const code = urlParams.get('code');
    if (code && !settingsUser.auth.company.integrations.profileId) {
      integrateSalesforce(authorization, code, settingsUser._id);
    }
    const {
      auth: {
        company: { integrations }
      }
    } = settingsUser;
    return (
      <div className="Settings_comms_container">
        <div className="Settings_comms_header">Integrations</div>
        <div className="Settings_integrations_account_container">
          <div className="Settings_Integrations_apps_instance_container">
            <div className="Settings_Integrations_apps_instance_banner">
              <img
                src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/salesforce_logo.png"
                className="Settings_Integrations_apps_instance_banner_logo"
                alt="salesforce"
              />
              <div className="Settings_Integrations_apps_instance_banner_name">Salesforce</div>
              <div className="Settings_Integrations_apps_instance_banner_description">
                Find and Nurture Leads
              </div>
            </div>
            <div className="Settings_Integrations_apps_instance_options">
              {!integrations.profileId && (
                <a
                  href={`https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9vtcvGoeH2bjMAapQoIg5vWdeyuN92JQTWOHq4ocPDxKeX3TSk4aPgNUa4HO3biJ1ocOVphDciLEsiFUl&redirect_uri=${WEB_URL}/dashboard/settings/integrations`}
                >
                  <div className="Settings_integrations_account_item_details">Connect</div>
                </a>
              )}
              {integrations.profileId && (
                <div className="Settings_integrations_account_item_details connected">
                  Connected
                </div>
              )}
            </div>
          </div>
          <div style={{ height: '30px' }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, settings: { settingsUser } }) => ({
  authorization,
  settingsUser
});

const mapDispatchToProps = {
  integrateSalesforce
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
