import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Grid, Modal, Button } from 'semantic-ui-react';
// import { create } from 'domain';
import CustomTeamBanner from '../../common/banners/CustomTeamBanner';
import { history } from '../../../../store/configureStore';
import { publishTeam } from '../../../../reducers/sub_reducers/teamCreate';

class Publication extends React.Component {
  state = {
    modalState: false
  };

  publishTeam = () => {
    this.setState({ modalState: true });
  };

  closeModal = () => {
    this.setState({ modalState: false });
  };

  openModal = async () => {
    const { createdTeam, publishTeam } = this.props;
    if (createdTeam.profile.questions.currentStep > 3) {
      history.push(`/dashboard/teamlist/active`);
    } else {
      await publishTeam(createdTeam._id);
      this.setState({ modalState: true });
    }
  };

  chooseReps = () => {
    const { createdTeam } = this.props;
    history.push(`/dashboard/teams/${createdTeam._id}/manage/chooserep`);
  };

  render() {
    const { createdTeam } = this.props;
    if (!createdTeam) return <div>loading...</div>;
    // console.log(createdTeam);
    const { questions } = createdTeam.profile;
    return (
      <React.Fragment>
        <Container className="TeamCreate_Container">
          <Grid>
            <Grid.Row className="TeamCreate_Container_Header">
              <div className="TeamCreate_Header">
                <div>
                  This is your finalized banner
                  {createdTeam.profile.questions.currentStep <= 3 &&
                    ', please click publish to continue'}
                  !
                </div>
              </div>
            </Grid.Row>
            <div className="Publication_Container_Body">
              <Grid.Column width={10} className="Publication_Second_Col">
                <div className="Publication_Second_Col_Custom_Team_Banner">
                  <CustomTeamBanner
                    team={createdTeam}
                    color={questions.bannerColor}
                    name={questions.name}
                    description={questions.description}
                  />
                </div>
              </Grid.Column>
            </div>
            <Grid.Row className="Customization_Container_Bottom_Nav">
              <Link to={`/dashboard/create/${createdTeam._id}/customization`}>
                <button className="Recruitment_Bottom_Nav_Button" type="button">
                  Back
                </button>
              </Link>
              <button
                className="Customization_Button_Next ready"
                type="button"
                onClick={this.openModal}
              >
                {createdTeam.profile.questions.currentStep > 3 ? 'Save' : 'Publish'}
              </button>
            </Grid.Row>
          </Grid>
        </Container>
        <Modal open={this.state.modalState} onClose={this.closeModal} size="mini" closeIcon>
          <div className="Modal_Container">
            <Modal.Header className="Modal_Header">Congratulations!</Modal.Header>
            <Modal.Content>
              <div className="Modal_Container_Content">
                <div className="Modal_Content">
                  Your team banner is ready! Please proceed to ZoomRep selection!
                </div>
                <img
                  src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamCreate/confetti.png"
                  className="Modal_Content_Pic"
                  alt="Modal_Picture"
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <div className="Modal_Container_Buttons">
                <Link to="/dashboard/">
                  <Button negative className="Modal_Container_Button_Select_Reps">
                    Go Home
                  </Button>
                </Link>
                <Button
                  positive
                  className="Modal_Container_Button_Go_Home ready"
                  onClick={this.chooseReps}
                >
                  Select Reps
                </Button>
              </div>
            </Modal.Actions>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, teamCreate: { createdTeam } }) => ({
  authorization,
  createdTeam
});

const mapDispatchToProps = {
  publishTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(Publication);
