import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { signIn } from '../../../reducers/sub_reducers/auth';
import notify from '../../../common/utils/notify';

class SignIn extends React.Component {
  state = { email: '', password: '', remember: false, showModal: 'none' };

  componentDidMount() {
    this.setState({
      remember: !!localStorage.getItem('email'),
      email: localStorage.getItem('email') || '',
      password: localStorage.getItem('password') || ''
    });
  }

  openModal = () => {
    this.setState({ showModal: 'block' });
  };

  closeModal = () => {
    if (this.state.showModal === 'block') {
      this.setState({ showModal: 'none' });
    }
  };

  repLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.metriczoom.android&hl=en_US',
        '_blank'
      );
      return this.closeModal();
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open('https://apps.apple.com/us/app/metriczoom/id1482028062?ls=1', '_blank');
      return this.closeModal();
    }
    // Else open ios app page
    window.open('https://apps.apple.com/us/app/metriczoom/id1482028062?ls=1', '_blank');
    return this.closeModal();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  signIn = event => {
    event.preventDefault();
    const { signIn } = this.props;
    const { email, password, remember } = this.state;
    if (!email || !password) {
      return notify('error', 'You forgot your email or password!');
    }
    return signIn(email.toLowerCase(), password, remember);
  };

  toggleRemember = () => {
    this.setState(
      prevState => ({ remember: !prevState.remember }),
      () => {
        if (!this.state.remember) {
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
      }
    );
  };

  render() {
    const { isAuthenticated } = this.props;

    return (
      <div className="SignIn_container" onClick={this.closeModal} onKeyDown={this.closeModal}>
        <form onSubmit={this.signIn}>
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">Login to your account</h2>
            <div className="SignIn_form_input_container">
              <div className="SignIn_form_input_email_container">
                <input
                  className="SignIn_form_input_email"
                  placeholder="Email"
                  value={this.state.email}
                  type="email"
                  name="email"
                  onChange={this.onChange}
                />
              </div>
              <div className="SignIn_form_input_password_container">
                <input
                  className="SignIn_form_input_password"
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="SignIn_form_option_container">
              <div className="SignIn_form_option_forgot">
                <Link to="forgot">Lost your Password?</Link>
              </div>
            </div>
            <div className="SignIn_form_submit_container">
              <div className="SignIn_form_option_remember">
                <label htmlFor="remember">Remember me</label>
                <input
                  type="checkbox"
                  id="remember"
                  name="remember"
                  checked={this.state.remember}
                  onChange={this.toggleRemember}
                  autoComplete="off"
                />
              </div>
              <button type="submit" onClick={this.signIn}>
                Login
                {isAuthenticated && <Redirect to="/dashboard" />}
              </button>
            </div>
            <div className="SignIn_helper_container">
              <span className="SignIn_helper_bottom">
                Don&apos;t have an account?{' '}
                <a
                  href="#"
                  id="myBtnBottom"
                  className="button primary wide"
                  onClick={this.openModal}
                >
                  &nbsp;Learn More
                </a>
              </span>

              <div
                id="myModal"
                className="modal-investor"
                style={{ display: this.state.showModal }}
              >
                <div className="modal-investor-content">
                  <p className="modal-investor-question">Are you a Rep or a Company?</p>
                  <div className="investor-close-container">
                    <span className="investor-close">&times;</span>
                  </div>
                  <div className="modal-investor-button-group">
                    <div id="repBtn" onClick={this.repLink} onKeyPress={this.repLink}>
                      Rep
                    </div>
                    <a href="https://mzoom.co/demo" target="_blank" rel="noopener noreferrer">
                      <div id="companyBtn">Company</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({ isAuthenticated });
const mapDispatchToProps = {
  signIn
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
