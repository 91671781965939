import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { setAuthorization } from '../reducers/sub_reducers/auth';
import { LANDING_URL } from '../common/utils/url';

// import private component
import PrivateRoute from '../common/utils/PrivateRoute';
import Auth from './auth/Auth';
import Dashboard from './dashboard/Dashboard';
import NotFound from './NotFound';
import Notification from '../common/toastNotifications/Toast';
import MobileRedirect from './MobileRedirect';

class Root extends Component {
  componentDidMount() {
    if (this.props.location.pathname !== '/') {
      this.props.setAuthorization();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/"
            // eslint-disable-next-line no-return-assign
            render={() => (window.location = LANDING_URL)}
          />
          <Route exact path="/login" render={() => <Redirect to="/auth/signin" />} />
          <Route exact path="/signup" render={() => <Redirect to="/auth/signup" />} />
          <Route exact path="/mobile-redirect" component={MobileRedirect} />
          <Route path="/auth" component={Auth} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route path="/*" component={NotFound} />
        </Switch>
        <Notification />
      </React.Fragment>
    );
  }
}

Root.propTypes = {
  setAuthorization: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  setAuthorization
};

export default hot(module)(withRouter(connect(null, mapDispatchToProps)(Root)));
