import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';

class Connected extends React.Component {
  state = {};

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { detailTeam } = this.props;
    if (!detailTeam) return <div>Loading</div>;
    const {
      profile: { company }
    } = detailTeam;
    return (
      <div className="Integrations_apps_container">
        <Grid columns={3} stackable>
          {company.integrations && company.integrations.profileId && (
            <Grid.Column>
              <div className="Integrations_apps_instance_container">
                <div className="Integrations_apps_instance_banner">
                  <img
                    src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/salesforce_logo.png"
                    className="Integrations_apps_instance_banner_logo"
                    alt="salesforce"
                  />
                  <div className="Integrations_apps_instance_banner_name">Salesforce</div>
                  <div className="Integrations_apps_instance_banner_description">
                    Find and Nurture Leads
                  </div>
                </div>
                <div className="Integrations_apps_instance_options">
                  {/* <button type="button" className="Integrations_apps_instance_options_settings">
                  SETTINGS
                </button> */}
                  {/* Need to add check for if it is salesforce */}
                  <button type="button" className="Integrations_apps_instance_options_connected">
                    CONNECTED
                  </button>
                </div>
              </div>
            </Grid.Column>
          )}
        </Grid>
        {(!company.integrations || !company.integrations.profileId) && (
          <div className="Connected_Placeholder_container">
            <img
              src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/placeholder.png"
              className="Connected_Placeholder_image_container"
              alt="Placeholder for empty team"
            />
            <div className="Connected_Placeholder_text_container">
              You do not have any active Integrations!
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user, authorization }, teamDetail: { detailTeam } }) => ({
  user,
  authorization,
  detailTeam
});

export default connect(mapStateToProps)(Connected);
