import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
// import { setAuthorization } from '../../reducers/sub_reducers/auth';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated === true) {
        return <Component {...props} />;
      }
      if (isAuthenticated === false) {
        return <Redirect to="/auth/signin" />;
      }
      return <div>Loading</div>;
    }}
  />
);

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({ isAuthenticated });

export default connect(mapStateToProps)(PrivateRoute);
