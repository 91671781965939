import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import notify from '../../../common/utils/notify';
import { API_URL } from '../../../common/utils/url';

export default class Forgot extends React.Component {
  state = { email: '', sent: false };

  sendForgotEmail = event => {
    event.preventDefault();
    if (!this.state.email) {
      return notify('error', 'Please input an email!');
    }
    return axios
      .post(`${API_URL}/v1/auth/forgot`, {
        email: this.state.email
      })
      .then(() => {
        this.setState({ sent: true });
        notify('success', 'Reset password email sent!');
      })
      .catch(error => {
        notify('error', error.response.data.message);
        this.setState({ sent: false });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    if (this.state.sent) {
      return (
        <div className="SignIn_container">
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">Please check your email!</h2>
            <div className="SignIn_helper_container">
              <span>Back to </span>
              <Link to="signin">Sign In</Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="SignIn_container">
        <form onSubmit={this.sendForgotEmail}>
          <div className="SignIn_form_container">
            <h2 className="SignIn_header">Reset Password Request</h2>
            <div className="SignIn_form_input_container">
              <div className="SignIn_form_input_email_container">
                <div className="SignIn_form_input_email_divider" />
                <input
                  className="SignIn_form_input_email"
                  placeholder="Email"
                  value={this.state.email}
                  type="email"
                  name="email"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="Forgot_form_submit_container">
              <button type="submit" onClick={this.sendForgotEmail}>
                Reset Password
              </button>
            </div>
            <div className="SignIn_helper_container">
              <span>Did you remember your password? </span>
              <Link to="signin">&nbsp; Sign In</Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
