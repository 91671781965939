import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';
// Local
import notify from '../../../../common/utils/notify';
import { createTeam } from '../../../../reducers/sub_reducers/teamCreate';
import { getTeamList } from '../../../../reducers/sub_reducers/teamList';
import { API_URL } from '../../../../common/utils/url';
// Components
import TeamBanner from '../../common/banners/TeamBanner';

const stepEnums = {
  0: 'recruitment',
  1: 'compensation',
  2: 'customization',
  3: 'customization',
  4: 'publication'
};

class Saved extends React.Component {
  state = { modalState: false, selectedTeam: null };

  deleteTeam = team => {
    const { authorization, user, getTeamList } = this.props;
    axios
      .delete(`${API_URL}/v1/team/${team._id}`, {
        headers: {
          authorization
        }
      })
      .then(() => {
        getTeamList(user.auth.company._id, authorization);
        notify('success', 'Team deleted!');
        this.closeModal();
      })
      .catch(error => {
        notify('error', error.response.data.message);
      });
  };

  selectTeam = team => {
    this.setState({ selectedTeam: team, modalState: true });
  };

  closeModal = () => {
    this.setState({ modalState: false });
  };

  render() {
    const { authorization, createTeam, teamsSaved, location, teamsCompany } = this.props;
    // Get not deleted teams and replace the middle grid thing
    if (!teamsSaved) return <div>Loading</div>;
    const visibleTeams = teamsSaved.reduce((acc, team) => {
      if (!team.deletedAt) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return (
      <React.Fragment>
        {visibleTeams > 0 ? (
          <Grid columns={3} container doubling stackable>
            {visibleTeams === 1 && <Grid.Column></Grid.Column>}
            {teamsSaved.map(team => {
              if (!team.deletedAt) {
                return (
                  <Grid.Column key={team._id} className="TeamList_sub_grid_padding">
                    <TeamBanner company={teamsCompany} team={team} location={location} />
                    <div className="Saved_menu_container">
                      <Link
                        to={`/dashboard/create/${team._id}/${
                          stepEnums[team.profile.questions.currentStep]
                        }`}
                      >
                        <div className="Saved_menu_edit">
                          <img
                            src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamList/pen.svg"
                            alt="edit"
                            className="Saved_menu_edit_icon"
                          />
                        </div>
                      </Link>
                      <div
                        className="Saved_menu_delete"
                        onClick={() => {
                          this.selectTeam(team);
                        }}
                        onKeyDown={this.handleKeydown}
                      >
                        <Icon fitted name="delete" className="Saved_menu_delete_icon" />
                      </div>
                    </div>
                  </Grid.Column>
                );
              }
              return <React.Fragment key={team._id}></React.Fragment>;
            })}
            <Modal open={this.state.modalState} size="mini" onClose={this.closeModal} closeIcon>
              <Modal.Header>Oh No!</Modal.Header>
              <Modal.Content>
                <p> You are about to cancel this team! Are you sure?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  onClick={() => {
                    this.deleteTeam(this.state.selectedTeam);
                  }}
                >
                  Yes, Cancel
                </Button>
                <Button positive onClick={this.closeModal}>
                  No, Don&apos;t Cancel
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid>
        ) : (
          <div className="List_empty_filler">
            <img
              src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/common/sadDog.png"
              alt="Sad Face"
              className="List_empty_filler_image"
            />
            <p className="List_empty_filler_top">There are no saved teams currently.</p>
            <p className="List_empty_filler_bottom">Please create a new one.</p>
            <button
              type="button"
              onClick={() => {
                createTeam(authorization);
              }}
              name="create"
              className="List_empty_filler_create"
            >
              Let&apos;s Start!
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  auth: { authorization, user },
  teamList: { teamsSaved, teamsCompany }
}) => ({
  authorization,
  teamsSaved,
  teamsCompany,
  user
});

const mapDispatchToProps = {
  createTeam,
  getTeamList
};

export default connect(mapStateToProps, mapDispatchToProps)(Saved);
