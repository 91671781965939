/* eslint-disable */
export let API_URL = '';
export let WEB_URL = '';
export let LANDING_URL = '';
export let PLAID_ENV = '';
export let STRIPE_KEY = '';


switch (process.env.NODE_ENV) {
  case 'production':
    WEB_URL = 'https://app.metriczoom.com';
    API_URL = 'https://mz-production.herokuapp.com';
    LANDING_URL = 'https://metriczoom.com';
    STRIPE_KEY = 'pk_live_hyG57Z58baE2iTXICT5qytmH';
    PLAID_ENV = 'development';
    break;
  case 'staging':
    WEB_URL = 'https://mz-staging.netlify.com';
    API_URL = 'https://mz-staging.herokuapp.com';
    LANDING_URL = 'https://mz-staging.netlify.com/auth';
    STRIPE_KEY = 'pk_test_zS6hZ5mAQZkchTjFYt4hK9tL';
    PLAID_ENV = 'sandbox';
    break;
  case 'testing':
    WEB_URL = 'https://mz-testing.netlify.com';
    API_URL = 'https://mz-testing.herokuapp.com';
    LANDING_URL = 'https://mz-testing.netlify.com/auth';
    STRIPE_KEY = 'pk_test_zS6hZ5mAQZkchTjFYt4hK9tL';
    PLAID_ENV = 'sandbox';
    break;
  default:
    WEB_URL = 'http://localhost:4000';
    API_URL = 'http://localhost:3000';
    LANDING_URL = 'http://localhost:4000/auth';
    STRIPE_KEY = 'pk_test_zS6hZ5mAQZkchTjFYt4hK9tL';
    PLAID_ENV = 'sandbox';
}

export default { API_URL, WEB_URL, PLAID_ENV, STRIPE_KEY, LANDING_URL };
