import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import { Container, Grid, Input, TextArea } from 'semantic-ui-react';
import CustomTeamBanner from '../../common/banners/CustomTeamBanner';
import { editCreateTeam } from '../../../../reducers/sub_reducers/teamCreate';
import ImageCropCreate from '../../common/ImageCropCreate';

class Customization extends React.Component {
  state = {
    isColorPickerVisible: false,
    bgColorSelector: 'orange',
    bannerTeamName: '',
    bannerTeamDescription: ''
  };

  componentDidMount() {
    const { createdTeam } = this.props;
    this.setState({
      bgColorSelector: createdTeam.profile.questions.bannerColor,
      bannerTeamName:
        createdTeam.profile.questions.name === 'Unamed'
          ? ''
          : createdTeam.profile.questions.name || '',
      bannerTeamDescription: createdTeam.profile.questions.description || ''
    });
  }

  updateTeam = () => {
    const { bgColorSelector, bannerTeamName, bannerTeamDescription } = this.state;
    const teamId = this.props.createdTeam._id;
    this.props.editCreateTeam(2, teamId, {
      bannerColor: bgColorSelector,
      description: bannerTeamDescription,
      name: bannerTeamName
    });
  };

  fillNameField = event => {
    if (event.target.value.length <= 18) {
      this.setState({ bannerTeamName: event.target.value });
    }
  };

  fillDescriptionField = event => {
    if (event.target.value.length <= 180) {
      this.setState({ bannerTeamDescription: event.target.value });
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleColorPicker = event => {
    event.stopPropagation();
    this.setState(prevState => ({ isColorPickerVisible: !prevState.isColorPickerVisible }));
  };

  hideColorPicker = () => {
    this.setState({ isColorPickerVisible: false });
  };

  handleChangeComplete = color => {
    this.setState({ bgColorSelector: color.hex });
    this.hideColorPicker();
  };

  render() {
    const { isColorPickerVisible } = this.state;
    const { createdTeam } = this.props;
    if (!createdTeam) return <div>loading...</div>;

    return (
      <React.Fragment>
        <Container className="TeamCreate_Container">
          <Grid>
            <Grid.Row className="TeamCreate_Container_Header">
              <div className="TeamCreate_Header">
                <div>Create how your Team Banner will appear to your matched ZoomReps.</div>
              </div>
            </Grid.Row>
            <div className="Customization_Container_Body" onKeyDown={() => {}}>
              <div className="Customization_First_Col">
                <ImageCropCreate />
                <div className="Customization_Container_divider_1" />
                <div className="Customization_First_Col_Selectors">
                  <div className="Customization_Container_ColorSelector">
                    <div className="Customization_Container_ColorSelector_Text">
                      Select your color scheme header
                    </div>
                    <div
                      className="Customization_Color_Selector"
                      onClick={this.toggleColorPicker}
                      onKeyDown={this.toggleColorPicker}
                      style={{ backgroundColor: this.state.bgColorSelector }}
                    />
                    <SketchPicker
                      color={this.state.bgColorSelector}
                      onChangeComplete={this.handleChangeComplete}
                      position="below"
                      className={`ColorPicker ${isColorPickerVisible ? '' : 'hidden'}`}
                    />
                  </div>
                  <div className="Customization_Container_divider_2" />
                  <div className="Customization_Container_TeamName">
                    <div className="Customization_Container_TeamName_Text">
                      Team Name (18 char max)
                    </div>
                    <Input
                      className="Customization_Container_TeamName_Input"
                      onChange={this.fillNameField}
                      value={this.state.bannerTeamName}
                      placeholder="e.g. Alpha Team"
                    />
                  </div>
                  <div className="Customization_Container_divider_3" />
                  <div className="Customization_Container_Compensation_Description">
                    <div className="Customization_Container_Compensation_Description_Text">
                      Team Description (180 char max)
                    </div>
                    <div className="Customization_Container_Compensation_Description_TextArea_Container">
                      <TextArea
                        rows="5"
                        onChange={this.fillDescriptionField}
                        value={this.state.bannerTeamDescription}
                        placeholder="Please type your description here"
                        className="Customization_Container_Compensation_Description_TextArea"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Grid.Column width={10} className="Customization_Second_Col">
                <div className="Customization_Second_Col_Custom_Team_Banner">
                  <CustomTeamBanner
                    team={createdTeam}
                    color={this.state.bgColorSelector}
                    name={this.state.bannerTeamName}
                    description={this.state.bannerTeamDescription}
                  />
                </div>
              </Grid.Column>
            </div>
            <Grid.Row className="Customization_Container_Bottom_Nav">
              <Link to={`/dashboard/create/${createdTeam._id}/compensation`}>
                <button className="Recruitment_Bottom_Nav_Button" type="button">
                  Back
                </button>
              </Link>
              <button
                className={`Customization_Button_Next ${this.state.bannerTeamName &&
                  this.state.bannerTeamDescription &&
                  'ready'}`}
                type="button"
                onClick={this.updateTeam}
              >
                Next
              </button>
            </Grid.Row>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth: { authorization }, teamCreate: { createdTeam } }) => ({
  authorization,
  createdTeam
});

const mapDispatchToProps = {
  editCreateTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(Customization);
