import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import configureStore, { history } from './store/configureStore';
import Root from './routes/Root';
import 'react-toastify/dist/ReactToastify.css';
import './public/styles/main.scss';

// If production, use LogRocket
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('nt8gmr/metriczoom');
  Sentry.init({
    dsn: 'https://a4f07a330e274ea085469705a5647232@sentry.io/1792936',
    environment: process.env.NODE_ENV
  });
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}
if (process.env.NODE_ENV === 'staging') {
  Sentry.init({
    dsn: 'https://a4f07a330e274ea085469705a5647232@sentry.io/1792936',
    environment: process.env.NODE_ENV
  });
}

const store = configureStore();
require('./favicon.ico');

render(
  <AppContainer>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </Provider>
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./routes/Root', () => {
    const NewRoot = require('./routes/Root').default;
    render(
      <AppContainer>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <NewRoot />
          </ConnectedRouter>
        </Provider>
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
