import React from 'react';
import { connect } from 'react-redux';
import { Modal, Loader } from 'semantic-ui-react';

import Slider from 'react-slick';
import RepBanner from '../../../common/banners/RepBanner';

import {
  fireRep,
  hireTempReps,
  rejectApplication
} from '../../../../../reducers/sub_reducers/teamDetail';
import notify from '../../../../../common/utils/notify';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  adaptiveHeight: true
};
class ChooseRep extends React.Component {
  state = {
    tempReps: [],
    hiring: false
  };

  fireRep = job => {
    const { authorization, fireRep } = this.props;
    fireRep(authorization, job);
  };

  addTempRep = rep => {
    if (this.state.tempReps.includes(rep))
      return notify('error', 'Rep already added, please confirm reps!');
    return this.setState(prevState => ({ tempReps: [...prevState.tempReps, rep] }));
  };

  fireTempRep = application => {
    if (!this.state.tempReps.includes(application))
      return notify('error', 'Error, cannot remove rep!');
    return this.setState(
      prevState => ({
        tempReps: [...prevState.tempReps].filter(item => item !== application)
      }),
      () => {
        notify('success', `${application.rep.profile.firstName} has been removed!`);
      }
    );
  };

  rejectApplication = application => {
    const { authorization, rejectApplication } = this.props;
    rejectApplication(authorization, application).then(() => {
      if (this.state.tempReps.includes(application))
        this.setState(prevState => ({
          tempReps: [...prevState.tempReps].filter(item => item !== application)
        }));
    });
  };

  hireTempReps = () => {
    const { authorization, hireTempReps } = this.props;
    const { tempReps } = this.state;
    this.setState({ hiring: true });
    hireTempReps(authorization, tempReps).then(() => {
      return this.setState({ tempReps: [], hiring: false });
    });
  };

  render() {
    const { detailTeam, detailJobs, detailApplications } = this.props;
    if (!detailTeam || !detailJobs || !detailApplications) return <div>Loading</div>;
    const filteredJobs = detailJobs.filter(job => {
      return job.status === 'hired';
    });
    const { tempReps } = this.state;
    const hireTrigger = (
      <button type="button" className="Detail_manage_rep_approve_temps">
        Confirm new reps!
      </button>
    );
    return (
      <div>
        <div className="Detail_manage_rep_header">
          <div className="Detail_manage_rep_header_main">
            Select from{' '}
            <span className="Detail_manage_rep_header_stat">{detailApplications.length}</span>{' '}
            ZoomReps
          </div>
          <div className="Detail_manage_rep_header_sub">to complete your team</div>
          {tempReps.length > 0 && (
            <Modal trigger={hireTrigger} size="mini" closeIcon className="Modal_fire_rep">
              <Modal.Header>
                <div className="Detail_manage_rep_header_confirm_header">
                  Are you sure you want to hire these reps?
                </div>
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  {!this.state.hiring && (
                    <button
                      type="button"
                      onClick={this.hireTempReps}
                      className="Detail_manage_rep_approve_temps_confirm"
                    >
                      Hire Reps
                    </button>
                  )}
                  {this.state.hiring && (
                    <button type="button" className="Detail_manage_rep_approve_temps_confirm">
                      <Loader active inline />
                    </button>
                  )}
                </Modal.Description>
              </Modal.Content>
            </Modal>
          )}
        </div>
        <div className="Detail_manage_rep_chosen_container">
          {filteredJobs.map(job => {
            const trigger = (
              <button type="button" className="Detail_manage_rep_chosen_remove">
                Remove
              </button>
            );
            return (
              <div className="Detail_manage_rep_chosen_box" key={job._id}>
                <img
                  className="Detail_manage_rep_chosen_image"
                  src={job.rep.profile.profilePicture}
                  alt="Rep"
                />
                <Modal trigger={trigger} size="mini" closeIcon className="Modal_fire_rep">
                  <Modal.Header>Are you sure you want to fire this rep?</Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      <button
                        className="Detail_manage_rep_chosen_fire_button"
                        type="button"
                        onClick={() => {
                          this.fireRep(job);
                        }}
                      >
                        Fire Rep
                      </button>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
                <div className="Detail_manage_rep_chosen_name">{job.rep.profile.firstName}</div>
              </div>
            );
          })}
          {tempReps.map(temp => {
            return (
              <div className="Detail_manage_rep_chosen_box" key={temp._id}>
                <img
                  className="Detail_manage_rep_chosen_image"
                  src={temp.rep.profile.profilePicture}
                  alt="Rep"
                />
                <button
                  onClick={() => {
                    this.fireTempRep(temp);
                  }}
                  type="button"
                  className="Detail_manage_rep_chosen_remove"
                >
                  Remove
                </button>
                <div className="Detail_manage_rep_chosen_name">{temp.rep.profile.firstName}</div>
              </div>
            );
          })}
          {filteredJobs.length === 0 && tempReps.length === 0 && (
            <div>
              <img
                src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/Choose_Rep_Rep_Placeholder.png"
                alt="Rep Placeholder"
                className="Choose_Rep_Icon_Placeholder_image_container"
              />
            </div>
          )}
        </div>
        <div className="Detail_manage_rep_carousel_container">
          {detailApplications.length > 1 && (
            <Slider {...sliderSettings} className="Detail_mange_rep_carousel_slider">
              {detailApplications.map(application => (
                <div className="Detail_manage_rep_carousel_item" key={application._id}>
                  <RepBanner
                    application={application}
                    rejectApplication={this.rejectApplication}
                    addTempRep={this.addTempRep}
                  />
                </div>
              ))}
            </Slider>
          )}
          {detailApplications.length === 1 && (
            <div className="Detail_manage_rep_carousel_item">
              <RepBanner
                application={detailApplications[0]}
                rejectApplication={this.rejectApplication}
                addTempRep={this.addTempRep}
              />
            </div>
          )}
          {detailApplications.length === 0 && (
            <div className="Choose_Rep_Placeholder_container">
              <img
                src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/Choose_Rep_Placeholder.png"
                className="Choose_Rep_Placeholder_image_container"
                alt="Placeholder for empty team"
              />
              <div className="Choose_Rep_Placeholder_text_container">
                Please wait for ZoomReps to apply to your team!
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user, authorization },
  teamDetail: { detailTeam, detailJobs, detailApplications }
}) => ({
  user,
  authorization,
  detailTeam,
  detailJobs,
  detailApplications
});

const mapDispatchToProps = {
  fireRep,
  hireTempReps,
  rejectApplication
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseRep);
