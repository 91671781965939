import React from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Container, Grid } from 'semantic-ui-react';
// Local
import { getTeamList } from '../../../reducers/sub_reducers/teamList';
import { createTeam } from '../../../reducers/sub_reducers/teamCreate';
// Components
import Active from './sub_team_list/Active';
import Saved from './sub_team_list/Saved';

class TeamList extends React.Component {
  componentDidMount() {
    const { user, authorization, getTeamList } = this.props;
    getTeamList(user.auth.company._id, authorization);
  }

  createTeam = async () => {
    const { authorization, createTeam } = this.props;
    createTeam(authorization);
  };

  render() {
    const {
      location: { pathname },
      user,
      teamsCompany
    } = this.props;
    if (!user || !teamsCompany) return <div>Loading...</div>;
    const activeItem = pathname.endsWith('active') ? 'active' : 'saved';
    return (
      <React.Fragment>
        <Grid columns="equal" className="List_nav">
          <Grid.Column className="List_nav_left">
            <div className="List_nav_left_location">Teams</div>
          </Grid.Column>
          <Grid.Column width={8} className="List_nav_center">
            <Button.Group className="List_nav_center_buttons">
              <Button
                as={NavLink}
                to="/dashboard/teamlist/active"
                name="active"
                active={activeItem === 'active'}
                className="List_nav_center_button_left"
              >
                Active
              </Button>
              <Button
                as={NavLink}
                to="/dashboard/teamlist/saved"
                name="saved"
                className="List_nav_center_button_right"
                active={activeItem === 'saved'}
              >
                Saved
              </Button>
            </Button.Group>
          </Grid.Column>
          <Grid.Column className="List_nav_right">
            {teamsCompany.teams.length > 0 && (
              <Button
                name="create"
                onClick={this.createTeam}
                floated="right"
                className="List_nav_right_button"
              >
                Create New Team
              </Button>
            )}
          </Grid.Column>
        </Grid>
        <Container className="List_main">
          <Switch>
            <Route
              exact
              path="/dashboard/teamlist"
              component={() => <Redirect to="/dashboard/teamlist/active" />}
            />
            <Route path="/dashboard/teamlist/active" component={Active} />
            <Route path="/dashboard/teamlist/saved" component={Saved} />
            <Route
              path={`${this.props.match.path}/*`}
              render={() => {
                return <Redirect to="/NotFound" />;
              }}
            />
          </Switch>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth: { user, authorization }, teamList: { teamsCompany } }) => ({
  user,
  authorization,
  teamsCompany
});

const mapDispatchToProps = {
  createTeam,
  getTeamList
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
