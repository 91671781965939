import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, Redirect, Switch } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { getSettingsUser, getCompanyStripe } from '../../../reducers/sub_reducers/settings';

import Comms from './sub_settings/Comms';
import Payment from './sub_settings/Payment';
import Company from './sub_settings/Company';
import Integrations from './sub_settings/Integrations';
import Quit from './sub_settings/Quit';
import Security from './sub_settings/Security';

class Settings extends React.Component {
  componentDidMount() {
    const { user, authorization, getSettingsUser, getCompanyStripe } = this.props;
    getSettingsUser(user._id, authorization);
    if (user.auth.company.wallet.stripeId) getCompanyStripe(authorization);
  }

  render() {
    const {
      location: { pathname }
    } = this.props;
    return (
      <div className="Settings">
        <div className="Settings_sidebar_container">
          <div className="Settings_sidebar_nav_container">
            <NavLink to="/dashboard/settings/company">
              <div
                className={
                  pathname.endsWith('company')
                    ? 'Settings_sidebar_nav_item_active'
                    : 'Settings_sidebar_nav_item_inactive '
                }
              >
                <Icon name="users" className="Settings_sidebar_nav_item_icon" />
              </div>
            </NavLink>
            <NavLink to="/dashboard/settings/payment">
              <div
                className={
                  pathname.endsWith('payment')
                    ? 'Settings_sidebar_nav_item_active'
                    : 'Settings_sidebar_nav_item_inactive '
                }
              >
                <Icon name="university" className="Settings_sidebar_nav_item_icon" />
              </div>
            </NavLink>
            <NavLink to="/dashboard/settings/integrations">
              <div
                className={
                  pathname.endsWith('integrations')
                    ? 'Settings_sidebar_nav_item_active'
                    : 'Settings_sidebar_nav_item_inactive '
                }
              >
                <Icon name="linkify" className="Settings_sidebar_nav_item_icon" />
              </div>
            </NavLink>
            <NavLink to="/dashboard/settings/comms">
              <div
                className={
                  pathname.endsWith('comms')
                    ? 'Settings_sidebar_nav_item_active'
                    : 'Settings_sidebar_nav_item_inactive '
                }
              >
                <Icon name="address book" className="Settings_sidebar_nav_item_icon" />
              </div>
            </NavLink>
            <NavLink to="/dashboard/settings/security">
              <div
                className={
                  pathname.endsWith('security')
                    ? 'Settings_sidebar_nav_item_active'
                    : 'Settings_sidebar_nav_item_inactive '
                }
              >
                <Icon name="shield" className="Settings_sidebar_nav_item_icon" />
              </div>
            </NavLink>
            <NavLink to="/dashboard/settings/quit">
              <div
                className={
                  pathname.endsWith('quit')
                    ? 'Settings_sidebar_nav_item_active'
                    : 'Settings_sidebar_nav_item_inactive '
                }
              >
                <Icon name="trash alternate" className="Settings_sidebar_nav_item_icon" />
              </div>
            </NavLink>
          </div>
        </div>
        <div className="Settings_content_container">
          <Switch>
            <Route
              exact
              path="/dashboard/settings"
              component={() => <Redirect to="/dashboard/settings/company" />}
            />
            <Route path="/dashboard/settings/company" component={Company} />
            <Route path="/dashboard/settings/comms" component={Comms} />
            <Route path="/dashboard/settings/integrations" component={Integrations} />
            <Route path="/dashboard/settings/payment" component={Payment} />
            <Route path="/dashboard/settings/security" component={Security} />
            <Route path="/dashboard/settings/quit" component={Quit} />
            <Route
              path="/dashboard/settings/*"
              render={() => {
                return <Redirect to="/NotFound" />;
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user, authorization } }) => ({
  user,
  authorization
});

const mapDispatchToProps = {
  getSettingsUser,
  getCompanyStripe
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
