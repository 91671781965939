import axios from 'axios';
// import * as api from '../../common/utils/api';
import { API_URL } from '../../common/utils/url';
import notify from '../../common/utils/notify';
import { history } from '../../store/configureStore';

const exampleInitialState = {
  createdTeam: undefined
};

export const actionTypes = {
  CREATE_TEAM: 'CREATE_TEAM',
  GET_CREATED_TEAM: 'GET_CREATED_TEAM',
  EDIT_CREATED_TEAM: 'EDIT_CREATED_TEAM',
  PUBLISH_TEAM: 'PUBLISH_TEAM'
};

export const createTeam = authorization => async dispatch => {
  try {
    const team = await axios.post(`${API_URL}/v1/team`, null, {
      headers: {
        authorization
      }
    });
    history.push(`/dashboard/create/${team.data._id}/recruitment`);
    return dispatch({
      type: actionTypes.CREATE_TEAM,
      createdTeam: team.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const getCreatedTeam = (authorization, teamId) => async dispatch => {
  try {
    const team = await axios.get(`${API_URL}/v1/team/${teamId}`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.GET_CREATED_TEAM,
      createdTeam: team.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const editCreateTeam = (stepNumber, teamId, response) => async dispatch => {
  try {
    const authorization = localStorage.getItem('JWT');
    const { data } = await axios.put(
      `${API_URL}/v1/team/${teamId}/question`,
      { step: stepNumber, response },
      { headers: { authorization } }
    );
    switch (stepNumber) {
      case 0: {
        history.push(`/dashboard/create/${teamId}/compensation`);
        break;
      }
      case 1: {
        history.push(`/dashboard/create/${teamId}/customization`);
        break;
      }
      case 2: {
        history.push(`/dashboard/create/${teamId}/publication`);
        break;
      }
      case 3: {
        history.push(`/dashboard/create/${teamId}/`);
        break;
      }
      default: {
        console.log('Not a valid choice');
        break;
      }
    }
    return dispatch({
      type: actionTypes.EDIT_CREATED_TEAM,
      createdTeam: data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const publishTeam = teamId => async dispatch => {
  try {
    const authorization = localStorage.getItem('JWT');
    console.log(teamId);
    const { data } = await axios.put(
      `${API_URL}/v1/team/${teamId}/publish`,
      {},
      { headers: { authorization } }
    );
    return dispatch({
      type: actionTypes.PUBLISH_TEAM,
      createdTeam: data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const teamCreateReducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TEAM:
      return Object.assign({}, state, {
        createdTeam: action.createdTeam
      });
    case actionTypes.GET_CREATED_TEAM:
      return Object.assign({}, state, {
        createdTeam: action.createdTeam
      });
    case actionTypes.EDIT_CREATED_TEAM:
      return Object.assign({}, state, {
        createdTeam: action.createdTeam
      });
    case actionTypes.PUBLISH_TEAM:
      return Object.assign({}, state, {
        createdTeam: action.createdTeam
      });
    default:
      return state;
  }
};
