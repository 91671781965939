/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { API_URL } from '../../common/utils/url';
import notify from '../../common/utils/notify';
import { history } from '../../store/configureStore';

const exampleInitialState = {
  detailTeam: null,
  detailBalance: null,
  detailTransactions: null,
  detailJobs: null,
  detailApplications: null
};

export const actionTypes = {
  GET_TEAM_JOBS: 'GET_TEAM_JOBS',
  GET_TEAM_APPLICATIONS: 'GET_TEAM_APPLICATIONS',
  GET_TEAM_DETAIL: 'GET_TEAM_DETAIL',
  GET_TEAM_BALANCE: 'GET_TEAM_BALANCE',
  GET_TEAM_TRANSACTIONS: 'GET_TEAM_TRANSACTIONS',
  HIRE_TEMP_REPS: 'HIRE_TEMP_REPS',
  REJECT_APPLICATION: 'REJECT_APPLICATION',
  FIRE_REP: 'FIRE_REP'
};

export const getTeamJobs = (authorization, teamId) => async dispatch => {
  try {
    const foundJobs = await axios.get(`${API_URL}/v1/team/${teamId}/jobs`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.GET_TEAM_JOBS,
      detailJobs: foundJobs.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const getTeamApplications = (authorization, teamId) => async dispatch => {
  try {
    const foundApplications = await axios.get(`${API_URL}/v1/team/${teamId}/applications`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.GET_TEAM_APPLICATIONS,
      detailApplications: foundApplications.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const getTeamDetail = (authorization, teamId) => async dispatch => {
  try {
    const foundTeam = await axios.get(`${API_URL}/v1/team/${teamId}`, {
      headers: {
        authorization
      }
    });
    if (foundTeam.data.profile.questions.currentStep < 3) {
      history.goBack();
      return notify('error', 'You must finish your team setup before you can go to manage!');
    }
    return dispatch({
      type: actionTypes.GET_TEAM_DETAIL,
      detailTeam: foundTeam.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};
export const getTeamBalance = (authorization, teamId) => async dispatch => {
  try {
    const foundBalance = await axios.get(`${API_URL}/v1/team/${teamId}/balance`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.GET_TEAM_BALANCE,
      detailBalance: foundBalance.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};
export const getTeamTransactions = (authorization, teamId) => async dispatch => {
  try {
    const foundTransactions = await axios.get(`${API_URL}/v1/team/${teamId}/transactions`, {
      headers: {
        authorization
      }
    });
    return dispatch({
      type: actionTypes.GET_TEAM_TRANSACTIONS,
      detailTransactions: foundTransactions.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const fireRep = (authorization, job) => async dispatch => {
  try {
    const firedRep = await axios.put(`${API_URL}/v1/job/${job._id}/fire`, null, {
      headers: {
        authorization
      }
    });
    const foundJobs = await axios.get(`${API_URL}/v1/team/${job.team}/jobs`, {
      headers: {
        authorization
      }
    });
    notify('success', `${firedRep.data.rep.profile.firstName} has successfully been fired!`);
    return dispatch({
      type: actionTypes.FIRE_REP,
      detailJobs: foundJobs.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const hireTempReps = (authorization, applications) => async dispatch => {
  try {
    if (applications.length < 1) {
      return notify('error', 'You must have at least one application to accept!');
    }
    await axios.post(
      `${API_URL}/v1/job/application/hire`,
      { jobs: applications },
      {
        headers: {
          authorization
        }
      }
    );
    const foundApplications = await axios.get(
      `${API_URL}/v1/team/${applications[0].team}/applications`,
      {
        headers: {
          authorization
        }
      }
    );
    const foundJobs = await axios.get(`${API_URL}/v1/team/${applications[0].team}/jobs`, {
      headers: {
        authorization
      }
    });
    notify('success', 'All selected reps were hired!');
    return dispatch({
      type: actionTypes.HIRE_TEMP_REPS,
      detailApplications: foundApplications.data,
      detailJobs: foundJobs.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const rejectApplication = (authorization, application) => async dispatch => {
  try {
    const rejectedJob = await axios.put(
      `${API_URL}/v1/job/${application._id}/application`,
      { status: 'rejected' },
      {
        headers: {
          authorization
        }
      }
    );
    const foundApplications = await axios.get(
      `${API_URL}/v1/team/${application.team}/applications`,
      {
        headers: {
          authorization
        }
      }
    );
    notify('success', `${rejectedJob.data.rep.profile.firstName} was rejected!`);
    return dispatch({
      type: actionTypes.REJECT_APPLICATION,
      detailApplications: foundApplications.data
    });
  } catch (error) {
    console.error(error);
    return notify('error', error.response.data.message);
  }
};

export const teamDetailReducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEAM_JOBS:
      return Object.assign({}, state, {
        detailJobs: action.detailJobs
      });
    case actionTypes.GET_TEAM_APPLICATIONS:
      return Object.assign({}, state, {
        detailApplications: action.detailApplications
      });
    case actionTypes.GET_TEAM_DETAIL:
      return Object.assign({}, state, {
        detailTeam: action.detailTeam
      });
    case actionTypes.GET_TEAM_BALANCE:
      return Object.assign({}, state, {
        detailBalance: action.detailBalance
      });
    case actionTypes.GET_TEAM_TRANSACTIONS:
      return Object.assign({}, state, {
        detailTransactions: action.detailTransactions
      });
    case actionTypes.HIRE_TEMP_REPS:
      return Object.assign({}, state, {
        detailJobs: action.detailJobs,
        detailApplications: action.detailApplications
      });
    case actionTypes.REJECT_APPLICATION:
      return Object.assign({}, state, {
        detailApplications: action.detailApplications
      });
    case actionTypes.FIRE_REP:
      return Object.assign({}, state, {
        detailJobs: action.detailJobs
      });
    default:
      return state;
  }
};
