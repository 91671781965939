import React from 'react';
import { connect } from 'react-redux';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { getCompanyStripe, changeCompanyStripe } from '../../../../reducers/sub_reducers/settings';
import { refetchUser } from '../../../../reducers/sub_reducers/auth';
import CardForm from './CardForm';
import { STRIPE_KEY } from '../../../../common/utils/url';

class Settings extends React.Component {
  state = {
    updating: false
  };

  setNewBank = token => {
    const { authorization, changeCompanyStripe, refetchUser } = this.props;
    changeCompanyStripe(authorization, token).then(() => {
      refetchUser();
      this.setState({ updating: false });
    });
  };

  update = () => {
    this.setState({ updating: true });
  };

  render() {
    const { settingsUser, companyStripe } = this.props;
    if (!settingsUser) return <div>Loading...</div>;
    const {
      auth: {
        company: { wallet }
      }
    } = settingsUser;
    return (
      <div className="Settings_comms_container">
        <div className="Settings_comms_header">Payment Settings</div>
        <div className="Settings_payments_account_container">
          {wallet.stripeId && companyStripe && companyStripe.paymentSources.length > 0 && (
            <React.Fragment>
              <div className="Settings_payments_account_item_container">
                <img
                  className="Settings_payments_account_item_logo"
                  src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/settings/chase_logo2.jpg"
                  alt="logo"
                />
                <div className="Settings_payments_account_item_name">
                  {companyStripe.paymentSources[0].brand}
                </div>
                <div className="Settings_payments_account_item_details">
                  ****{companyStripe.paymentSources[0].last4}
                </div>
              </div>
              <div style={{ height: '25px' }} />
            </React.Fragment>
          )}
          {!this.state.updating && (
            <button type="button" onClick={this.update} className="Card_button">
              Add New Card
            </button>
          )}
          {(wallet.stripeId && companyStripe && companyStripe.paymentSources.length <= 0) ||
            (this.state.updating && (
              <StripeProvider apiKey={STRIPE_KEY}>
                <Elements>
                  <CardForm setNewBank={this.setNewBank} user={this.props.user} />
                </Elements>
              </StripeProvider>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { authorization, user },
  settings: { settingsUser, companyStripe }
}) => ({
  authorization,
  user,
  settingsUser,
  companyStripe
});

const mapDispatchToProps = {
  changeCompanyStripe,
  getCompanyStripe,
  refetchUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
