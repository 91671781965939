import axios from 'axios';
import { API_URL } from '../../common/utils/url';
import notify from '../../common/utils/notify';
import { history } from '../../store/configureStore';

const jwt = require('jsonwebtoken');

const exampleInitialState = {
  authorization: undefined,
  isAuthenticated: undefined,
  user: undefined
};

export const actionTypes = {
  SET_AUTHORIZATION: 'SET_AUTHORIZATION',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  REFETCH_USER: 'REFETCH_USER'
};

export const setAuthorization = () => async dispatch => {
  const authorization = localStorage.getItem('JWT');
  if (authorization) {
    try {
      const decodedUser = jwt.decode(authorization.split(' ')[1]);
      const user = await axios.get(`${API_URL}/v1/user/${decodedUser._id}`, {
        headers: { authorization }
      });
      if (!user.data.auth.emailVerification.verified) {
        history.push('/auth/verification');
        return notify('error', 'Please verify your email!');
      }
      notify('success', 'Welcome back!');
      return dispatch({
        type: actionTypes.SET_AUTHORIZATION,
        authorization: localStorage.getItem('JWT'),
        user: user.data,
        isAuthenticated: user.data.auth.emailVerification.verified
      });
    } catch (error) {
      // notify('error', error.response.data.message);
      return dispatch({
        type: actionTypes.SET_AUTHORIZATION,
        authorization: undefined,
        user: undefined,
        isAuthenticated: false
      });
    }
  } else {
    localStorage.removeItem('JWT');
    return dispatch({
      type: actionTypes.SET_AUTHORIZATION,
      authorization: undefined,
      user: undefined,
      isAuthenticated: false
    });
  }
};

export const signIn = (email, password, remember) => async dispatch => {
  try {
    if (!email || !password) return notify('error', 'You must input an email and password!');
    const user = await axios.post(`${API_URL}/v1/auth/signin/local`, {
      email,
      password
    });
    if (remember) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    }
    if (!user.data.user.auth.emailVerification.verified) {
      history.push('/auth/verification');
      return notify('error', 'Please verify your email!');
    }
    localStorage.setItem('JWT', user.data.token);
    notify('success', 'Signed in!');
    return dispatch({
      type: actionTypes.SIGN_IN,
      authorization: user.data.token,
      user: user.data.user,
      isAuthenticated: true
    });
  } catch (error) {
    return notify('error', error.response.data.message);
  }
};

export const signOut = () => async dispatch => {
  localStorage.removeItem('JWT');
  notify('error', 'Signed out!');
  return dispatch({
    type: actionTypes.SIGN_OUT,
    authorization: undefined,
    user: undefined,
    isAuthenticated: false
  });
};

export const refetchUser = () => async dispatch => {
  const authorization = localStorage.getItem('JWT');
  if (authorization) {
    try {
      const decodedUser = jwt.decode(authorization.split(' ')[1]);
      const user = await axios.get(`${API_URL}/v1/user/${decodedUser._id}`, {
        headers: { authorization }
      });
      if (!user.data.auth.emailVerification.verified) {
        history.push('/auth/verification');
        return notify('error', 'Please verify your email!');
      }
      return dispatch({
        type: actionTypes.SET_AUTHORIZATION,
        authorization: localStorage.getItem('JWT'),
        user: user.data,
        isAuthenticated: user.data.auth.emailVerification.verified
      });
    } catch (error) {
      notify('error', error.response.data.message);
      return dispatch({
        type: actionTypes.SET_AUTHORIZATION,
        authorization: undefined,
        user: undefined,
        isAuthenticated: false
      });
    }
  } else {
    localStorage.removeItem('JWT');
    return dispatch({
      type: actionTypes.REFETCH_USER,
      authorization: undefined,
      user: undefined,
      isAuthenticated: false
    });
  }
};

export const authReducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTHORIZATION:
      return Object.assign({}, state, {
        authorization: action.authorization,
        user: action.user,
        isAuthenticated: action.isAuthenticated
      });
    case actionTypes.SIGN_OUT:
      return Object.assign({}, state, {
        authorization: action.authorization,
        user: action.user,
        isAuthenticated: action.isAuthenticated
      });
    case actionTypes.SIGN_IN:
      return Object.assign({}, state, {
        authorization: action.authorization,
        user: action.user,
        isAuthenticated: action.isAuthenticated
      });
    case actionTypes.REFETCH_USER:
      return Object.assign({}, state, {
        authorization: action.authorization,
        user: action.user,
        isAuthenticated: action.isAuthenticated
      });
    default:
      return state;
  }
};
