import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Modal } from 'semantic-ui-react';
import notify from '../../../../../common/utils/notify';
import { API_URL } from '../../../../../common/utils/url';

class Transfers extends React.Component {
  state = { modalState: false, selectedTransfer: null };

  requestRefund = conversion => {
    const { authorization } = this.props;
    axios
      .post(
        `${API_URL}/v1/ticket`,
        {
          models: { conversion },
          type: 'refund',
          title: 'Refund request',
          description: 'Refund'
        },
        {
          headers: {
            authorization
          }
        }
      )
      .then(() => {
        notify('success', 'Refund requested!');
        this.closeModal();
      })
      .catch(error => {
        notify('error', error.response.data.message);
        console.error(error);
        this.closeModal();
      });
  };

  selectTransfer = transfer => {
    this.setState({ selectedTransfer: transfer, modalState: true });
  };

  closeModal = () => {
    this.setState({ modalState: false });
  };

  render() {
    const { detailTeam, detailBalance, detailTransactions } = this.props;
    if (!detailTeam || !detailBalance || !detailTransactions) return <div>Loading</div>;
    const lastTransfer =
      detailTransactions.transfers.length > 0
        ? detailTransactions.transfers[detailTransactions.transfers.length - 1].metadata.leadPrice
        : 0;
    return (
      <div className="Payments_history_container">
        <div className="Payments_history_banner_container">
          <div className="Payments_history_banner_balance">
            <div className="Payments_history_banner_balance_title">Current Balance</div>
            <div className="Payments_history_banner_balance_amount">
              ${(detailBalance.balance / 100).toLocaleString()}
            </div>
          </div>
          <div className="Payments_history_banner_bank">
            <img
              src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/stripeLogo.png"
              alt="chase"
              className="Payments_history_banner_bank_image"
            />
          </div>
          <div className="Payments_history_banner_last">
            <div className="Payments_history_banner_last_title">Last Transfer</div>
            {lastTransfer > 0 && (
              <div className="Payments_history_banner_last_amount">
                ${(lastTransfer / 100).toLocaleString()}
              </div>
            )}
            {lastTransfer === 0 && <div className="Payments_history_banner_last_amount">TBD</div>}
          </div>
        </div>
        <div className="Payments_history_list_container">
          <div className="Payments_history_list_title">
            {detailTransactions.transfers.length > 0 && 'Transfers'}
          </div>
          <div className="Payments_list_grid">
            {detailTransactions.transfers.map(transfer => {
              return (
                <div className="Payments_list_grid_item" key={transfer.id}>
                  <div className="Payments_list_grid_item_top_container">
                    <div className="Payments_list_grid_item_top_status success">
                      <div className="Payments_list_grid_item_top_status_text">Successful</div>
                    </div>
                    <div className="Payments_list_grid_item_top_amount">
                      ${(transfer.metadata.leadPrice / 100).toLocaleString()}
                    </div>
                    <div className="Payments_list_grid_item_top_date">
                      {moment(transfer.created * 1000).format('l')}
                    </div>
                    <div className="Payments_list_grid_item_top_id">
                      ID: {transfer.id.slice(-8)}
                    </div>
                    <div className="Payments_list_grid_item_top_dispute">
                      <div
                        className="Payments_list_grid_item_top_dispute_text"
                        onClick={() => {
                          this.selectTransfer(transfer.metadata.conversion);
                        }}
                        onKeyDown={() => {
                          this.selectTransfer(transfer.metadata.conversion);
                        }}
                      >
                        Dispute
                      </div>
                    </div>
                  </div>
                  <div className="Payments_list_grid_item_divider" />
                  <div className="Payments_list_grid_item_description">{transfer.description}</div>
                </div>
              );
            })}
            {detailTransactions.transfers.length === 0 && (
              <div className="Payments_Placeholder_container">
                <img
                  src="https://mz-public-bucket.s3.us-east-2.amazonaws.com/images/teamDetail/credit+card.png"
                  className="Payments_Placeholder_image_container"
                  alt="Placeholder for empty team"
                />
                <div className="Payments_Placeholder_text_container">
                  You currently have no transfers at this time.
                </div>
              </div>
            )}
            <Modal
              closeIcon
              open={this.state.modalState}
              onClose={this.closeModal}
              size="mini"
              className="Modal_fire_rep"
            >
              <Modal.Header>
                Are you sure you want to request a refund for this conversion?
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <button
                    className="Detail_manage_rep_chosen_fire_button"
                    type="button"
                    onClick={() => {
                      this.requestRefund(JSON.parse(this.state.selectedTransfer));
                    }}
                    onKeyDown={this.requestRefund}
                  >
                    Request Refund
                  </button>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user, authorization },
  teamDetail: { detailTeam, detailBalance, detailTransactions }
}) => ({
  user,
  authorization,
  detailTeam,
  detailBalance,
  detailTransactions
});

export default connect(mapStateToProps)(Transfers);
