import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';

import { Button, Icon } from 'semantic-ui-react';

import Apps from './sub_integrations/Apps';
import Connected from './sub_integrations/Connected';
import DetailTeamBanner from '../../common/banners/DetailTeamBanner';

class Integrations extends React.Component {
  render() {
    const {
      location: { pathname },
      detailTeam
    } = this.props;
    const activeItem = pathname.endsWith('apps') ? 'apps' : 'connected';
    if (!detailTeam) return <div>Loading</div>;
    return (
      <div className="Detail_manage_container">
        <div className="Detail_side_container">
          <div className="Detail_side_nav">
            <Button.Group vertical className="Detail_side_nav_buttons">
              <Button
                className="Detail_side_nav_button_upper"
                as={NavLink}
                to={`${this.props.match.url}/apps`}
                style={{
                  color: activeItem === 'apps' ? 'white' : 'black',
                  backgroundColor: activeItem === 'apps' ? '#55bbec' : 'white'
                }}
              >
                Apps
                {activeItem === 'apps' && (
                  <Icon name="chevron right" className="Detail_side_nav_buttons_icon" />
                )}
              </Button>
              <Button
                style={{
                  color: activeItem === 'connected' ? 'white' : 'black',
                  backgroundColor: activeItem === 'connected' ? '#55bbec' : 'white'
                }}
                className="Detail_side_nav_button_lower"
                as={NavLink}
                to={`${this.props.match.url}/connected`}
              >
                Connected Apps
                {activeItem === 'connected' && (
                  <Icon name="chevron right" className="Detail_side_nav_buttons_icon" />
                )}
              </Button>
            </Button.Group>
          </div>
          <div className="Detail_side_banner_container">
            {detailTeam && <DetailTeamBanner team={detailTeam} location={location} />}
          </div>
        </div>
        <div className="Detail_integrations_main_container">
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}`}
              component={() => <Redirect to={`${this.props.match.url}/apps`} />}
            />
            <Route path={`${this.props.match.url}/apps`} component={Apps} />
            <Route path={`${this.props.match.url}/connected`} component={Connected} />
            <Route
              path={`${this.props.match.url}/*`}
              render={() => {
                return <Redirect to="/NotFound" />;
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user, authorization }, teamDetail: { detailTeam } }) => ({
  user,
  authorization,
  detailTeam
});

export default connect(mapStateToProps)(Integrations);
